// external imports
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
} from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useHistory } from 'react-router-dom';

// internal imports
import './Login.scss';
import JewelLogo from '../../assets/images/jewel logo.png';
import { AuthContext } from '../../context/AuthContextProvider';


const PasswordChanged = (props) => {
    const history = useHistory();
    const wrapper = React.createRef();
    const { otherInfo } = props;
    const [otherDetails, setOtherDetails] = useState(otherInfo);
    const [{ }, dispatch] = useContext(AuthContext); // , dispatch

    useEffect(() => {}, []);

    // Redirects to login page
    const onContinueToLogin = () => {
        history.push("/Login");
    };

    return (
        <div className="root">
            <div className="login_container">
                <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-fullWidth MuiInputBase-formControl"><img src={JewelLogo} alt="Logo" className="brand_logo"/></div>
                <div><p className="subtitle">CLINICAL STUDY PORTAL</p></div>
                <div className="login_windows">
                    <div className="custom_container">
                        <div className="forgot-password">
                        <ValidatorForm data-testid="ValidatorForm" onSubmit={onContinueToLogin}>
                            <div className="mb-5 pb-5 mt-5 pt-5 password-changed-title">Password successfully updated</div>
                            <div className="confirmation-text">
                                <br/>You have successfully reset your password. <br/> Please log-in with our new password.
                            </div>
                            <Button
                            id="loginButton"
                            type="submit"
                            name="loginButton"
                            color="primary"
                            data-testid="loginButton"
                            fullWidth
                            size="large"
                            style={{
                                height: "81px",
                                marginTop: "43px",
                                fontSize: "22px",
                                fontWeight: "600"
                            }}
                            variant="contained"
                            className="mb-20 login_btn_provider"
                            disabled={otherInfo.submitted}
                            >
                            Log In
                            </Button>
                        </ValidatorForm>
                        </div> {/* forgot password form */}
                    </div> {/* custom container */}
                </div> {/* login windows */}
            </div> {/* login container */}
        </div> //root
  );
};

PasswordChanged.propTypes = {
    otherInfo: PropTypes.shape({
        submitted: PropTypes.bool,
    }),
};

PasswordChanged.defaultProps = {
    otherInfo: {
        submitted: false,
    },
};

export default PasswordChanged;