// including dependancies
import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { patientReducer, initialPatientState } from '../reducers/PatientReducer';

// creating and exporting the auth context for login
export const PatientContext = createContext([{}, function () {}]);

// Create a provider for components to consume and subscribe to changes
export const PatientContextProvider = (props) => {
  const [patientState, dispatch] = useReducer(patientReducer, initialPatientState);
  const { children } = props;

  return (
    <PatientContext.Provider value={[patientState, dispatch]}>
      {children}
    </PatientContext.Provider>
  );
};

PatientContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
