import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
} from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useHistory } from 'react-router-dom';

// internal imports
import './Login.scss';
import JewelLogo from '../../assets/images/jewel logo.png';


const PasswordLimit = (props) =>{
    const history = useHistory();
    const wrapper = React.createRef();
    const { otherInfo } = props;

    // Redirects to login page
    const onContinueToLogin = () => {
        history.push("/Login");
    };

    return (
        <div className="root">
            <div className="login_container">
                <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-fullWidth MuiInputBase-formControl"><img src={JewelLogo} alt="Logo" className="brand_logo"/></div>
                <div><p className="subtitle">CLINICAL STUDY PORTAL</p></div>
                <div className="login_windows">
                    <div className="custom_container">
                        <div className="patient-password-limit">
                        <ValidatorForm data-testid="ValidatorForm" onSubmit={onContinueToLogin}>
                            <div className="patient-text text-h4">User</div>
                            <div className="confirmation-text" style={{marginTop: "50px"}}>
                            Sorry you've reached the maximum attempts at resetting your password.
                            <br/> 
                            <br/> 
                            If you need additional support, call Customer Service 1-800-985-5702.
                            </div>
                            <Button
                            id="patientLoginButton"
                            type="submit"
                            name="loginButton"
                            data-testid="patientLoginButton"
                            fullWidth
                            size="large"
                            style={{
                              backgroundColor: "#005f82",
                              color: "#fff",
                              height: "81px",
                              marginTop: "43px",
                              fontSize: "22px",
                              fontWeight: "600"
                            }}
                            variant="contained"
                            className="mb-20 login_btn_patient"
                            disabled={otherInfo.submitted}
                            >
                            Log In
                            </Button>
                        </ValidatorForm>
                        </div> {/* forgot password form */}
                    </div> {/* custom container */}
                </div> {/* login windows */}
            </div> {/* login container */}
        </div> //root
  );
}

PasswordLimit.propTypes = {
    otherInfo: PropTypes.shape({
        email: PropTypes.string,
    })
};

PasswordLimit.defaultProps = {
    otherInfo: {
        email: '',
    }
}

export default PasswordLimit;