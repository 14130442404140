export function setAuthCookies(accessToken, refreshToken) {
  
    // Set the cookies
    document.cookie = `accessToken=${accessToken};secure;`;
    document.cookie = `refreshToken=${refreshToken};secure;`;
  }


export function getAuthTokensFromCookies() {
    const cookies = document.cookie.split(';');
    const tokenObj = {
      accessToken: null,
      refreshToken: null,
    };
  
    for (const cookie of cookies) {
      const [name, value] = cookie.trim().split('=');
      if (name === 'accessToken') {
        tokenObj.accessToken = value;
      } else if (name === 'refreshToken') {
        tokenObj.refreshToken = value;
      }
    }
  
    return tokenObj;
  }

export function deleteCookie(cookieName) {
    // Set the cookie's expiration date to a past time
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }
  
// module.exports = {
//     setAuthCookies,
//     getAuthTokensFromCookies,
// };

