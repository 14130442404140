// External Imports
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Button,
  Typography,
  TextField,
  Box,
  makeStyles,
} from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import NavBar from '../../../components/sidebar/index';
import '../Patient.scss';
import TopBar from '../../../components/topbar/TopBarNav';
// Internal Imports
import { AuthContext } from '../../../context/AuthContextProvider';
import { PatientContext } from '../../../context/PatientContextProvider';
import actions from '../../../actions/Actions';
import AxiosInstance from '../../../common/ApiHandler';
import apiEndPoints from '../../../common/ApiEndPoints';
import { deleteCookie } from '../../../common/ApiHelper';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#F4F6F8',
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256,
    },
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    padding: '24px',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'hidden',
  },
}));

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function formatStatus(status) {
  let formattedStatus = '-'

  if(status){
    formattedStatus = status.charAt(0) + status.slice(1).toLowerCase();
  }

  return formattedStatus
}

function formatFullName(firstName, lastName) {
  let formattedName = `${firstName} ${lastName}`

  if(!firstName && !lastName){
    formattedName = "-"
  }

  return formattedName
}

// This function will prpare grid row by patient infroamtion
function Row(props) {
  const { row, history } = props;
  const classes = useRowStyles();

  // This function will handle table row click event for redirection
  const handleTableRowClick = (event, patientId) => {
    history.push({
      pathname: '/PatientDetails',
      search: `?${new URLSearchParams({ patientId }).toString()}`,
    });
  };

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell
          data-testid="tableCellFirstName"
          component="th"
          scope="row"
          className="table_name"
          onClick={(event) => handleTableRowClick(event, row.id)}
        >
          { formatFullName(row.first_name, row.last_name) }
        </TableCell>
        <TableCell className="table_data">
          {row.external_user_id ? row.external_user_id : '-'}
        </TableCell>
        <TableCell className="table_data">
          {row.date_of_birth ? row.date_of_birth : '-'}
        </TableCell>
        <TableCell className="table_data">
          {row.reports.length > 0 ? row.reports.length : '-'}
        </TableCell>
        <TableCell className="table_data">
          <span>
            { formatStatus(row.status) }
          </span>
        </TableCell>
      </TableRow>
    </>
  );
}

function NoRecord() {
  return (
    <>
      <TableRow>
        <TableCell colSpan={7} align="center">
          {' '}
          No Record Found
          {' '}
        </TableCell>
      </TableRow>
    </>
  );
}

const PatientList = (props) => {
  const { searchInfo, history } = props;
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  // eslint-disable-next-line no-empty-pattern
  const [{}, dispatchAuth] = useContext(AuthContext);
  const [statePatient, dispatch] = useContext(PatientContext);
  const [patientListInfo, setPatientList] = useState({});
  const [searchData, setsearchData] = useState(searchInfo);
  const [selectedDate, setSelectedDate] = React.useState(null);
  const pageSizeWrapper = React.createRef();
  const [isClearClicked, setClearClicked] = useState(false);
  const [page, setPage] = React.useState(1);
  const [PageSize, setPageSize] = React.useState(10);
  const [counter, setCounter] = useState(props.counter);

  useEffect(() => {
    function logoutUser() {
      deleteCookie('accessToken');
      sessionStorage.removeItem('userName');
      sessionStorage.removeItem('userStatus');
      sessionStorage.removeItem('counter');
      window.location.replace(`${window.location.origin}/Login`);
    }

    function decrementTime(count) {
      const remainingTime = count - 1
      sessionStorage.setItem('counter', remainingTime);
      setCounter(remainingTime)

    }

    if(counter <= 0) {
      logoutUser()
    }

    const timer = counter > 0 && setInterval(() => decrementTime(counter), 1000);

    return () => clearInterval(timer);
  }, [counter])


  useEffect(() => {
    setCounter(counter - 1)
  }, [])

  const onInputFieldChange = (event) => {
    if (event.target.id === 'report_id') {
      const reg = /^\d+$/;
      if (!(event.target.value === '') && !reg.test(event.target.value)) {
        return false;
      }
    }
    setsearchData({
      ...searchData,
      [event.target.id]: event.target.value,
    });

    return true;
  };

  // this function will call api to get patient list by search criteria
  const getPatientList = (event, callFrom) => {
    if (callFrom === 'Search' || callFrom === 'isClearClicked') {
      searchData.record_per_page = 10; // no of reacords par page
      searchData.page_number = 0; // page number
    } else if (callFrom === 'record_per_page') {
      searchData.page_number = 0; // page number
    }

    if (selectedDate) {
      searchData.date_of_birth = Moment(selectedDate).format('MM-DD-YYYY');
    } else {
      searchData.date_of_birth = '';
    }

    if (searchData.report_id === '') {
      searchData.report_id = 0;
    } else {
      searchData.report_id = Number(searchData.report_id);
    }

    searchData.first_name = searchData.first_name.trim();
    searchData.last_name = searchData.last_name.trim();
    searchData.external_user_id = searchData.external_user_id.trim();

    // dispatch a event to set loading when api request starts
    dispatchAuth({ type: actions.API_REQUEST_START });
    // Make api call to get patient list
    AxiosInstance.post(apiEndPoints.GET_PATIENT_LIST, searchData)
      .then((data) => {
        setClearClicked(false);
        // dispatch action to set loding state variable to false
        dispatchAuth({
          type: actions.API_REQUEST_END,
        });
        // dispatch action to set patient data in patient state
        dispatch({
          type: actions.PATIENT_LIST_SUCCESS,
          data: data.data,
        });
      })
      .catch((error) => {
        setClearClicked(false);
        // dispatch action to set loding state variable to false
        dispatchAuth({
          type: actions.API_REQUEST_END,
        });
        // dispatch action to set error state variable
        dispatch({
          type: actions.PATIENT_LIST_FAILURE,
          data: error.message,
        });
      });
  };

  const onClear = () => {
    setsearchData({
      first_name: '',
      last_name: '',
      external_user_id:'',
      date_of_birth: '', // for data pass for api
      report_id: 0,
      record_per_page: 10, // no of reacords par page
      page_number: 0, // page number
    });
    setSelectedDate(null);
    setClearClicked(true);
    setPage(1);
    setPageSize(10);
  };

  // This effect is used to set patient List information in local state for list binding
  useEffect(() => {
    setPatientList(statePatient.patientListInfo);
  }, [statePatient]);

  // This effect is use to fetch patient infroamtion
  useEffect((e) => {
    getPatientList(e, 'pageLoad');
  }, []);

  // This effect is use to get patient list by page no , page size or clear button change
  useEffect(
    (e) => {
      getPatientList(e, 'page_number');
    },
    [searchData.page_number],
  );

  useEffect(
    (e) => {
      getPatientList(e, 'record_per_page');
    },
    [searchData.record_per_page],
  );

  useEffect(
    (e) => {
      getPatientList(e, 'isClearClicked');
    },
    [isClearClicked],
  );

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <div className="patientlist_container">
              <Typography variant="h4" className="mb-16 SecondaryColor">
                Patient List
              </Typography>
              <div className="filter_wrapper">
                <TextField
                  fullWidth
                  className="custom_field"
                  margin="normal"
                  name="FirstName"
                  id="first_name"
                  type="text"
                  placeholder="First Name"
                  variant="outlined"
                  value={searchData.first_name}
                  onChange={(e) => onInputFieldChange(e)}
                />
                <TextField
                  fullWidth
                  className="custom_field"
                  margin="normal"
                  name="LastName"
                  id="last_name"
                  type="text"
                  placeholder="Last Name"
                  variant="outlined"
                  value={searchData.last_name}
                  onChange={(e) => onInputFieldChange(e)}
                />
                <TextField
                  fullWidth
                  className="custom_field"
                  margin="normal"
                  name="ExternalUserID"
                  id="external_user_id"
                  type="text"
                  placeholder="Patient ID"
                  variant="outlined"
                  value={searchData.external_user_id}
                  onChange={(e) => onInputFieldChange(e)}
                />
                <TextField
                  fullWidth
                  className="custom_field"
                  margin="normal"
                  name="ReportID"
                  id="report_id"
                  type="text"
                  value={searchData.report_id === 0 ? '' : searchData.report_id}
                  placeholder="Report ID"
                  variant="outlined"
                  onChange={(e) => onInputFieldChange(e)}
                />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    fullWidth
                    className="custom_field"
                    inputVariant="outlined"
                    format="MM-dd-yyyy"
                    margin="normal"
                    id="date_of_birth"
                    value={selectedDate}
                    onChange={(date) => {
                      setSelectedDate(date);
                    }}
                    placeholder="Date of Birth"
                    InputAdornmentProps={{ position: 'end' }}
                  />
                </MuiPickersUtilsProvider>

                <Button
                  className="search_btn"
                  margin="normal"
                  color="primary"
                  size="large"
                  type="button"
                  variant="contained"
                  id="clear_btn"
                  onClick={onClear}
                >
                  clear
                </Button>
                <Button
                  className="search_btn"
                  margin="normal"
                  color="primary"
                  size="large"
                  type="submit"
                  variant="contained"
                  id="search_btn"
                  onClick={(e) => {
                    setPage(1);
                    setPageSize(10);
                    getPatientList(e, 'Search');
                  }}
                >
                  Search
                </Button>
              </div>
              <div className="patient_table">
                <TableContainer component={Paper}>
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Full Name</TableCell>
                        <TableCell>Patient ID</TableCell>
                        <TableCell>Date of Birth</TableCell>
                        <TableCell>Number of Reports</TableCell>
                        <TableCell>Enrollment Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        patientListInfo && patientListInfo.data && patientListInfo.total > 0
                        ? (
                            patientListInfo.data.map((row) => (
                              <Row
                                history={history}
                                key={row.id}
                                row={row}
                                onclick={() => history.push('/PatientDetails')}
                              />
                            ))
                          )
                        : (
                            <NoRecord />
                          )
                      }
                    </TableBody>
                  </Table>
                  <div className="pagination_wrapper">
                    <Pagination
                      page={page}
                      count={
                        patientListInfo
                        && patientListInfo.total
                        && patientListInfo.record_per_page
                        && patientListInfo.total > 0
                        && patientListInfo.record_per_page > 0
                          ? Math.ceil(
                            patientListInfo.total
                                / patientListInfo.record_per_page,
                          )
                          : 1
                      }
                      onChange={(event, pageno) => {
                        setsearchData({
                          ...searchData,
                          page_number: pageno - 1,
                        });
                        setPage(pageno);
                      }}
                    />
                    <div className="result_wrapper">
                      <span className="mr-15">Results per page</span>
                      <Select
                        id="demo-simple-select-outlined"
                        variant="outlined"
                        defaultValue="10"
                        value={PageSize}
                        onChange={(event) => {
                          setsearchData({
                            ...searchData,
                            record_per_page: event.target.value,
                          });
                          setPageSize(event.target.value);
                        }}
                        ref={pageSizeWrapper}
                      >
                        <MenuItem key="1" value={10}>
                          10
                        </MenuItem>
                        <MenuItem key="2" value={20}>
                          20
                        </MenuItem>
                        <MenuItem key="3" value={30}>
                          30
                        </MenuItem>
                      </Select>
                    </div>
                  </div>
                </TableContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Row.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    date_of_birth: PropTypes.string,
    external_user_id: PropTypes.string,
    email: PropTypes.string,
    mobile: PropTypes.string,
    status: PropTypes.string,
    reports: PropTypes.arrayOf(PropTypes.number),
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

Row.defaultProps = {
  row: {
    id: 0,
    first_name: '',
    last_name: '',
    external_user_id: '',
    date_of_birth: '',
    email: '',
    mobile: '',
    status: '',
    reports: [],
  },
};

PatientList.propTypes = {
  searchInfo: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    external_user_id: PropTypes.string,
    date_of_birth: PropTypes.string,
    report_id: PropTypes.number,
    record_per_page: PropTypes.number, // no of reacords par page
    page_number: PropTypes.number, // page number
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  counter: PropTypes.string
};

PatientList.defaultProps = {
  searchInfo: {
    first_name: '',
    last_name: '',
    external_user_id: '',
    report_id: 0,
    date_of_birth: '', // for data pass for api
    record_per_page: 10, // no of reacords par page
    page_number: 0, // page number
  },
  counter: 0
};
export default withRouter(PatientList);
