import { ErrorSharp } from '@material-ui/icons';
import axios from 'axios';
import apiEndPoints from './ApiEndPoints';
import { setAuthCookies, getAuthTokensFromCookies } from './ApiHelper';
const defaultOptions = {
  baseURL: process.env.REACT_APP_END_POINT_URL,
  headers: {
    'Content-Type': 'application/json',
  },
};

const AxiosInstance = axios.create(defaultOptions);

AxiosInstance.interceptors.request.use(
  async request => {
    const { accessToken, refreshToken } = getAuthTokensFromCookies();
    if (request.url !== apiEndPoints.LOGIN) {
      request.headers.Authorization = accessToken;
    }
    if (request.url === apiEndPoints.UPDATE_PASSWORD) {
      request.headers.Authorization = accessToken;
    }
    if ((request.url === apiEndPoints.GET_REPORT_BY_ID && request.method.toUpperCase() == "GET") || request.url === apiEndPoints.GET_PATIENT_DETAILS) {
      request.headers = {"Content-Type": 'application/json', "Authorization": accessToken}
      request.data = {};
      request.responseType = 'application/pdf';
    }
    if(request.url === apiEndPoints.UPDATE_REPORT_UPLOAD_DATE && request.method.toUpperCase() == "POST"){
      request.headers = {"Content-Type": 'application/json', "Authorization": accessToken}
      request.params = request.data
    }
    return Promise.resolve(request);
  },
  (error) => error,
);

AxiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalConfig = error.config;
    if(error.response.data.message === "MFA Required"){
      throw error.response.data;
    }
    if (error.response.data.message === "Max OTP Attempts, Account Locked"){
      throw error.response.data;
    }
    else if (error.response.status == 401 && error.response.data.message == "Invalid Email Token: Unauthorized Access"){
      console.log(error.response);
      throw error.response.data;
    }
    // console.log(error.response.data.errors[0].detail);
    if (error.response.status == 401 && error.response.data.locked) {
      return error.response.data;
    } else if (error.response.status === 401 && error.response.data.errors[0].detail.includes("Token is Expired")) {
      const rs = await fetchNewToken();
      const newAccessToken = rs.data.id_token;
      const newRefreshToken = rs.data.refresh_token;
      setAuthCookies(newAccessToken, newRefreshToken);
      return AxiosInstance(originalConfig);
    }
    
    return Promise.reject(error);
  },
);

function fetchNewToken() {
  const userRole = sessionStorage.getItem('userRole');
  defaultOptions.headers.Authorization = sessionStorage.getItem('refreshToken');
  return axios.create(defaultOptions).post(apiEndPoints.REFRESH_TOKEN, {"role": userRole});
};

export default AxiosInstance;
