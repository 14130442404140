// including dependancies
import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { authReducer, initialLoginState } from '../reducers/AuthReducer';

// creating and exporting the auth context for login
export const AuthContext = createContext([{}, function () {}]);

// Create a provider for components to consume and subscribe to changes
export const AuthContextProvider = (props) => {
  const [authState, dispatch] = useReducer(authReducer, initialLoginState);
  const { children } = props;

  return (
    <AuthContext.Provider value={[authState, dispatch]}>
      {children}
    </AuthContext.Provider>
  );
};

AuthContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
