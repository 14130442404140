import React from 'react';
import './App.css';
import { ThemeProvider } from '@material-ui/styles';
import AppRoutes from './AppRoutes';
import MuiTheme from './theme';
import { AuthContextProvider } from './context/AuthContextProvider';
import './theme/theme.scss';

function App() {
  return (
    <ThemeProvider theme={MuiTheme}>
      <AuthContextProvider>
        <AppRoutes />
      </AuthContextProvider>
    </ThemeProvider>
  );
}

export default App;
