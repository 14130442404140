const Actions = {
  LOGIN: 'login',
  LOGIN_SUCCESS: 'loginSuccess',
  LOGIN_FAILURE: 'loginFailure',
  API_REQUEST_START: 'apiRequestStart',
  API_REQUEST_END: 'apiRequestEnd',
  API_RESPONSE_ERROR: 'apiResponseError',
  PATIENT_LIST_SUCCESS: 'patientListSuccess',
  PATIENT_LIST_FAILURE: 'patientListError',
  PATIENT_DETAILS_SUCCESS: 'patientDetailsSuccess',
  PATIENT_DETAILS_FAILURE: 'patientDetailsError',
  PATIENT_REPORT_SUCCESS: 'patientReportSuccess',
  PATIENT_REPORT_FAILURE: 'patientReportError',
  PINCODE_LEN_ERR: 'pinCodeLengthError',
  UPDATE_REPORT_UPLOAD_DATE_SUCCESS: 'patientReportSuccess',
  UPDATE_REPORT_UPLOAD_DATE_FAILURE: 'patientReportError',
  SET_USER_AUTHENTICATED: 'setuserauthenticated',
};
export default Actions;
