import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // Link as RouterLink,
import PropTypes from 'prop-types';

import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles,
  ListItem,
  Button,
  Menu
} from '@material-ui/core';
import {
  // Settings as SettingsIcon,
  Users as UsersIcon,
  LogOut as LogOutIcon,
} from 'react-feather';
// import userThumb from '../../assets/images/ic_user_thumb.png';
import { IC_USER_THUMB } from '../../constant/imageConst';
import NavItem from './NavItem';

const user = {
  avatar: IC_USER_THUMB,
  jobTitle: '',
  name: 'Provider Name',
};

// Navigation items for side bar
const items = [
  {
    href: '/PatientList',
    icon: UsersIcon,
    title: 'Patient List',
    className: 'demo',
  },
  // {
  //   href: '/PatientList',
  //   icon: SettingsIcon,
  //   title: 'My Account',
  //   className: 'demo',
  // },
  {
    href: '/Login',
    icon: LogOutIcon,
    title: 'Log Out',
    className: 'Logout',
  },
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
    marginTop: 30,
    marginBottom: 15,
  },
  name: {
    fontSize: '18px',
    color: '#263238',
    fontWeight: 600,
  },
  jobTitle: {
    color: '#999999',
    fontWeight: 400,
    fontSize: 16,
  },
  about_button: {
    marginRight:'20px',
    fontSize: '16px',
    color: '#0000008a',
    fontFamily: 'Source Sans Pro,sans-serif'
  },
  about_menu: {
    width: '250px',
    paddingLeft:'15px'
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const uName = sessionStorage.getItem('userName') ? sessionStorage.getItem('userName') : 'Provider Name';

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <Avatar className={classes.avatar}>
          {uName.split(' ')[0].charAt(0) + uName.split(' ')[1].charAt(0)}
        </Avatar>
        <Typography className={classes.name} color="textPrimary" variant="h5">
          {sessionStorage.getItem('userName')
            ? sessionStorage.getItem('userName')
            : user.name}
        </Typography>
        <Typography className={classes.jobTitle}>
          {/*sessionStorage.getItem('userRole')
            ? sessionStorage.getItem('userRole')
            : */user.jobTitle}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
              className={item.className}
            />
          ))}
          <ListItem>
          <Typography className={classes.about_button} onClick={handleClick}>About</Typography>
          <Menu
              className={classes.about_menu}
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <Typography className={classes.about_menu} variant='h3'>Clinical Portal</Typography>
              <Typography className={classes.about_menu} variant='subtitle1'>UDI: 00850047782083</Typography>
              <Typography className={classes.about_menu} variant='subtitle1'>Version: 2.01.000</Typography>
            </Menu>
          </ListItem>
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
  onMobileClose: () => {
  },
  openMobile: false,
};

export default NavBar;
