// external imports
/*
* @jest-environment jest-environment-jsdom-sixteen
*/
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Link,
  Button,
  Typography,
  Snackbar,
  IconButton,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { useHistory } from 'react-router-dom';

// internal imports
import './Login.scss';
import JewelLogo from '../../assets/images/jewel logo.png';
import actions from '../../actions/Actions';
import { AuthContext } from '../../context/AuthContextProvider';
import AxiosInstance from '../../common/ApiHandler';
import apiEndPoints from '../../common/ApiEndPoints';
import ReactCodeInput from 'react-code-input';
import { setAuthCookies, getAuthTokensFromCookies } from '../../common/ApiHelper';

const createTempRandomPassword = () => {
    let length              = 10;
    var result              = '';
    var characters          = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&,';
    var charactersLength    = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * 
      charactersLength));
    }
   return result;
}

const ConfirmationCode = (props) => {
    const history = useHistory();
    const wrapper = React.createRef();
    const [{ }, dispatch] = useContext(AuthContext); // , dispatch
    const { otherInfo, verificationInfo } = props;
    const pinSize = 6;
    const [openL, setOpenL] = useState(false);
    const [dialogBoxText, setDialogBoxText] = useState("No Text");
    const [dialogTitle, setDialogTitle] = useState("No Title");
    const [inputPinLen, setInputPinLen] = useState();
    const [verificationDetails, setVerificationDetails] = useState(verificationInfo);
    const [containerText, setContainerText] = useState("Enter verification code");
    const [containerTextStyle, setContainerTextStyle] = useState({
        color: "#707070",
        opacity: 1.0,
        fontSize: "40px",
        fontFamily: "Source Sans Pro",
        fontWeight: "400",
        lineHeight: 1.235,
        textAlign: "center",
    });

    const pinCodeStyle = {
        className: ReactCodeInput,
        inputStyle: {
            margin:  '7px',
            MozAppearance: 'textfield',
            width: '60px',
            fontSize: '48px',
            textAlign: 'center',
            height: '60px',
        }
    }

    // Revert text back to original text after API error response
    useEffect(() => {
        setTimeout(() => {
            setContainerText("Enter verification code");
            setContainerTextStyle({
                color: "#707070",
                opacity: 1.0,
                fontSize: "40px",
                fontFamily: "Source Sans Pro",
                fontWeight: "400",
                lineHeight: 1.235,
                textAlign: "center",
            });
        }, 7000);  // After 5.5 seconds revert text
    }, [containerText]);

    useEffect(() => {
        setVerificationDetails({
            ...verificationDetails,
            email: sessionStorage.getItem("userEmail"),
            password: createTempRandomPassword()
        });
    }, []);

    // This is for redirect to confirmation code after putting valid email address
    const onConfirmationCode = () => {
        const payload = {
            email: sessionStorage.getItem("userEmail"),
            password: createTempRandomPassword(),
            otp: verificationDetails.otp,
        }
        AxiosInstance.put(apiEndPoints.RESET_PASSWORD, payload)
        .then(() => {
            AxiosInstance.post(apiEndPoints.AUTH, {email: payload.email, password: payload.password})
            .then((data) => {
                setAuthCookies(data.data.id_token, data.data.refresh_token);
                history.push("/UpdatePassword");
            })
            .catch((error) => {
                dispatch({
                    type: actions.API_RESPONSE_ERROR,
                    data: error.message,
                });
            })
        })
        .catch((error) => {
            dispatch({
                type: actions.API_RESPONSE_ERROR,
                data: error.message,
            });
            if(error["message"] == "Max OTP Attempts, Account Locked"){
                setContainerText("Max Attempts Reached, Account Locked");
            }else{
                setContainerText("Verification Code Invalid");
            }
            setContainerTextStyle({
                color: "#C90C0C",
                opacity: 1.0,
                fontSize: "40px",
                fontFamily: "Source Sans Pro",
                fontWeight: "400",
                lineHeight: 1.235,
                textAlign: "center",
            });
        });
    };

    // Resend validation code
    const handleResendValidationCode = () => {
        let payload = {email: sessionStorage.getItem("userEmail")};
        AxiosInstance.put(apiEndPoints.RESET_PASSWORD, payload)
        .then(() => {
            setContainerText("Code Resent");
            setContainerTextStyle({
                color: "#4A8928",
                opacity: 1.0,
                fontSize: "40px",
                fontFamily: "Source Sans Pro",
                fontWeight: "400",
                lineHeight: 1.235,
                textAlign: "center",
            });
        })
        .catch((error) => {
            setContainerText("Too many requests. Try again later.");
            setContainerTextStyle({
                color: "#C90C0C",
                opacity: 1.0,
                fontSize: "40px",
                fontFamily: "Source Sans Pro",
                fontWeight: "400",
                lineHeight: 1.235,
                textAlign: "center",
            });
        });
        setOpenL(false);
    }

    // Provider text field form change
    const onPinCodeChange = (event) => {
        setVerificationDetails({
            ...verificationDetails,
            otp: event
        });
        setInputPinLen(event.length);
    };

    // When Help button is selected
    const onHelp = () => {
        let dialogTitle = "Would you like a new verification code to be emailed to you?";
        let dialogText = "Resend verification code";
        setOpenL(true);
        setDialogTitle(dialogTitle)
        setDialogBoxText(dialogText)
    };

    const handleCloseDialog = () => {
        setOpenL(false);
    };

    return (
        <div className="root">
            <div className="login_container">
                <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-fullWidth MuiInputBase-formControl"><img src={JewelLogo} alt="Logo" className="brand_logo"/></div>
                <div><p className="subtitle">CLINICAL STUDY PORTAL</p></div>
                <div className="login_windows">
                    <div className="custom_container">
                        <div className="forgot-password">
                        <ValidatorForm data-testid="ValidatorForm" onSubmit={onConfirmationCode}>
                            <div className="mb-4 mt-5 pt-5" style={containerTextStyle}>{containerText}</div>
                            <div 
                                className="mt-5 pt-5 forgot-password-text"
                                style={{
                                    marginTop: "43px",
                                    textAlign: "start",
                                }}
                            >Please enter the 6-digit verification code that was sent to your email.</div>
                            <ReactCodeInput 
                            type='text' 
                            fields={pinSize} 
                            {...pinCodeStyle} 
                            className="confirmation-code" 
                            onChange={onPinCodeChange}
                            style={{
                                marginTop: "56px",
                            }}
                            />
                            <Button
                            id="loginButton"
                            type="submit"
                            name="loginButton"
                            color="primary"
                            data-testid="loginButton"
                            fullWidth
                            size="large"
                            style={{
                                height: "81px",
                                marginTop: "43px",
                                fontSize: "22px",
                                fontWeight: "600"
                            }}
                            variant="contained"
                            className="mb-20 login_btn_provider"
                            disabled={inputPinLen !== pinSize ? true : false}
                            >
                            Continue
                            </Button>
                            <Typography align="right" color="textSecondary" variant="body1">
                            <Button
                                data-testid='helpButton' 
                                onClick={onHelp}
                                style={{
                                marginBottom: "74px",
                                fontSize: "20px",
                                fontWeight: "400"
                                }}
                                >
                                Help?
                            </Button>
                            </Typography>
                        </ValidatorForm>
                        </div> {/* forgot password form */}
                        <Dialog
                        open={openL}
                        onClose={handleCloseDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        className="confirm-dialog"
                        >
                        <div className="dialogCloseIcon">
                        <IconButton onClick={handleCloseDialog}>
                            <CloseIcon />
                        </IconButton>
                        </div>
                        <DialogTitle id="alert-dialog-title">
                            <Typography variant="h5" className="help-title">
                            <b>{dialogTitle}</b>
                            </Typography>
                        </DialogTitle>
                        <DialogContent className="mb-5 pb-5 help-title">
                            <DialogContentText id="scroll-dialog-description">
                            <Link
                                data-testid='resendCodeLink' 
                                onClick={handleResendValidationCode}
                                component="button"
                                variant="h5"
                                align="left"
                                >
                                {dialogBoxText}
                            </Link>
                            </DialogContentText>
                        </DialogContent>
                        </Dialog>
                    </div> {/* custom container */}
                </div> {/* login windows */}
            </div> {/* login container */}
        </div> //root
  );
};

ConfirmationCode.propTypes = {
    otherInfo: PropTypes.shape({
        confirmationCodeSent: PropTypes.bool,
        confirmationCodeFailedToSend: PropTypes.bool,
    }),
    verificationInfo: PropTypes.shape({
        email: PropTypes.string,
        otp: PropTypes.string,
        password: PropTypes.string,
    })
};

ConfirmationCode.defaultProps = {
    otherInfo: {
        confirmationCodeSent: false,
        confirmationCodeFailedToSend: false,
    },
    verificationInfo: {
        email: '',
        otp: '',
        password: '',
    },
};

export default ConfirmationCode;
