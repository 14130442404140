import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Avatar,
  Link,
  makeStyles,
  Button,
  // IconButton,
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Moment from 'moment';
import { Document, Page } from 'react-pdf/dist/entry.webpack';

// Internal Imports
import NavBar from '../../../components/sidebar/index';
import TopBar from '../../../components/topbar/TopBarNav';
import '../Patient.scss';
import { AuthContext } from '../../../context/AuthContextProvider';
import { PatientContext } from '../../../context/PatientContextProvider';
import actions from '../../../actions/Actions';
import AxiosInstance from '../../../common/ApiHandler';
import apiEndPoints from '../../../common/ApiEndPoints';
import { deleteCookie } from '../../../common/ApiHelper';
// for material style guild
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#F4F6F8',
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256,
    },
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    padding: '24px',
  },
  card: {
    height: '100%',
    borderRadius: 8,
    boxShadow: '3px 0 15px 0 rgba(0,0,0,0.03)',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'hidden',
  },
  avatar: {
    cursor: 'pointer',
    width: 77,
    height: 77,
    margin: '0 auto 20px',
    backgroundColor: '#47A1B6',
    fontSize: 28,
    fontWeight: 600,
  },
  email: {
    color: '#263238',
    fontWeight: 400,
    fontSize: 16,
    opacity: 0.7,
    marginBottom: '27px',
  },
}));

const PatientDetails = (props) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  // eslint-disable-next-line no-empty-pattern
  const [{ }, dispatchAuth] = useContext(AuthContext);
  const [statePatient, dispatch] = useContext(PatientContext);
  const [patientDetailInfo, setpatientDetailInfo] = React.useState({});
  // below state used for pdf viewer
  const [numPages, setNumPages] = useState(null);
  const [fileNameURL, SetFileNameURL] = useState('');
  const [currentReportId, SetCurrentReportId] = useState(0);
  const [counter, setCounter] = useState(props.counter);

  useEffect(() => {
    function logoutUser() {
      deleteCookie('accessToken');
      sessionStorage.removeItem('userName');
      sessionStorage.removeItem('userStatus');
      sessionStorage.removeItem('counter');
      window.location.replace(`${window.location.origin}/Login`);
    }

    function decrementTime(count) {
      const remainingTime = count - 1
      sessionStorage.setItem('counter', remainingTime);
      setCounter(remainingTime)

    }

    if(counter <= 0) {
      logoutUser()
    }

    const timer = counter > 0 && setInterval(() => decrementTime(counter), 1000);

    return () => clearInterval(timer);
  }, [counter])


  useEffect(() => {
    setCounter(counter - 1)
  }, [])

  // cancle dailoge
  const handleClose = () => {
    setOpen(false);
  };

  // open confirm upoad dailouge
  const handleClickOpenConfirm = (e, ReportId) => {
    SetCurrentReportId(ReportId);
    setConfirmOpen(true);
  };

  // cancle confirm upload dailoge
  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  // api call for mark uploaded date in database for documnet upload
  const handleUploadConfirm = () => {
    setConfirmOpen(false);
    // dispatch a event to set loading when api request starts
    dispatchAuth({ type: actions.API_REQUEST_START });
    AxiosInstance.post(apiEndPoints.UPDATE_REPORT_UPLOAD_DATE, {
      reportId: currentReportId,
    })
      .then((data) => {
        dispatchAuth({
          type: actions.API_REQUEST_END,
        });
        dispatch({
          type: actions.UPDATE_REPORT_UPLOAD_DATE_SUCCESS,
          data,
        });
      })
      .catch((error) => {
        dispatchAuth({
          type: actions.API_REQUEST_END,
        });
        dispatch({
          type: actions.UPDATE_REPORT_UPLOAD_DATE_FAILURE,
          data: error,
        });
      });
  };

  // file genration for pdf preview
  const generateFile = (content, fileName, CallFrom) => {
    if (CallFrom === 'paper') {
      SetFileNameURL(content.report_url);
      setOpen(true);
      setScroll(CallFrom);
    } else {
      const link = document.createElement('a');
      link.href = content.report_url;
      link.download = fileName;
      link.click();
    }
  };

  // Get report for preview
  const handleDownloadPreview = (e, patientReportId, CallFrom) => {
    // dispatch a event to set loading when api request starts
    dispatchAuth({ type: actions.API_REQUEST_START });
    AxiosInstance.get(`${apiEndPoints.GET_REPORT_BY_ID}`, {
      params: {
        reportId: patientReportId,
      },
    })
      .then((resposne) => {
        dispatchAuth({
          type: actions.API_REQUEST_END,
        });
        dispatch({
          type: actions.PATIENT_REPORT_SUCCESS,
          data: resposne,
        });
        generateFile(resposne.data, patientReportId, CallFrom);
      })
      .catch((error) => {
        dispatchAuth({
          type: actions.API_REQUEST_END,
        });
        dispatch({
          type: actions.PATIENT_REPORT_FAILURE,
          data: error,
        });
      });
  };

  // fuction call on react-pdf documnet load success to set total number of page
  function onDocumentLoadSuccess(pdf) {
    setNumPages(pdf.numPages);
  }

  // this function will call on page load to get all the patient information
  const getPatientDetailsById = () => {
    // eslint-disable-next-line react/prop-types
    const patientId = new URLSearchParams(props.location.search).get(
      'patientId',
    );
    // dispatch a event to set loading when api request starts
    dispatchAuth({ type: actions.API_REQUEST_START });
    AxiosInstance.get(`${apiEndPoints.GET_PATIENT_DETAILS}`, {
      params: {
        patientID: patientId,
      },
    })
      .then((data) => {
        dispatchAuth({
          type: actions.API_REQUEST_END,
        });
        dispatch({
          type: actions.PATIENT_DETAILS_SUCCESS,
          data,
        });
      })
      .catch((error) => {
        dispatchAuth({
          type: actions.API_REQUEST_END,
        });
        dispatch({
          type: actions.PATIENT_DETAILS_FAILURE,
          data: error,
        });
      });
  };

  // This effect will call on page load
  useEffect(() => {
    getPatientDetailsById();
  }, []);

  // This effect will call when user confirm the report upload so we need refresh the view
  useEffect(() => {
    getPatientDetailsById();
  }, [statePatient.reportUploadConfirmInfo]);

  const formatPhoneNumbers = (phone_number) => {
    if(phone_number == null){
      return "-";
    }
    if(phone_number.length < 9){
      return "-";
    }
    return phone_number.substring(0,3) + "-" + phone_number.substring(3,6) + "-" + phone_number.substring(6);
  }

  // This effect will call when get patient detail infromation
  useEffect(() => {
    if (statePatient.patientDetails && statePatient.patientDetails.data) {
      statePatient.patientDetails.data.mobile = formatPhoneNumbers(statePatient.patientDetails.data.mobile);
      statePatient.patientDetails.data.emergency_contact_number = formatPhoneNumbers(statePatient.patientDetails.data.emergency_contact_number);
      setpatientDetailInfo(statePatient.patientDetails.data);
    }
  }, [statePatient.patientDetails]);

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <div className="patientdetail_container">
              <Typography variant="h4" className="mb-16 SecondaryColor">
                Patient Details
              </Typography>
              <div className="font-14 mb-16">
                <Link href="/PatientList">
                  {' '}
                  <ArrowBackIosIcon style={{ fontSize: 10 }} />
                  {' '}
                  Back to Patient
                  List
                </Link>
              </div>
              <Container maxWidth={false} className="p-0">
                <Grid container spacing={3}>
                  <Grid item lg={4} md={4} xl={4} xs={12}>
                    <Card className={classes.card}>
                      <CardContent className="profile_card">
                        <div>
                          <Avatar className={classes.avatar}>
                            {patientDetailInfo.first_name
                              ? patientDetailInfo.first_name.substring(0, 1)
                              : '-'}
                            {patientDetailInfo.last_name
                              ? patientDetailInfo.last_name.substring(0, 1)
                              : '-'}
                          </Avatar>
                          <Typography variant="h5" className="SecondaryColor">
                            {patientDetailInfo.first_name
                              ? patientDetailInfo.first_name
                              : '-'}
                            {' '}
                            {patientDetailInfo.last_name
                              ? patientDetailInfo.last_name
                              : '-'}
                          </Typography>
                          <Typography className={classes.email}>
                            {patientDetailInfo.email
                              ? patientDetailInfo.email
                              : '-'}
                          </Typography>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item lg={8} md={8} xl={8} xs={12}>
                    <Card className={classes.card}>
                      <CardContent>
                        <ul className="profile_detail_list">
                          <li>
                            <div className="font-14"> Patient ID</div>
                            <Typography variant="body2">
                              {'  '}
                              {patientDetailInfo.external_user_id
                                ? patientDetailInfo.external_user_id
                                : '-'}
                            </Typography>
                          </li>
                          <li>
                            <div className="font-14"> First Name</div>
                            <Typography variant="body2">
                              {' '}
                              {patientDetailInfo.first_name
                                ? patientDetailInfo.first_name
                                : '-'}
                              {' '}
                            </Typography>
                          </li>
                          <li>
                            <div className="font-14"> Last Name</div>
                            <Typography variant="body2">
                              {' '}
                              {patientDetailInfo.last_name
                                ? patientDetailInfo.last_name
                                : '-'}
                              {' '}
                            </Typography>
                          </li>
                          <li>
                            <div className="font-14"> Date of Birth</div>
                            <Typography variant="body2">
                              {patientDetailInfo.date_of_birth
                                ? Moment(
                                  patientDetailInfo.date_of_birth,
                                ).format('MM-DD-YYYY')
                                : '-'}
                            </Typography>
                          </li>
                          <li>
                            <div className="font-14"> Phone Number</div>
                                                      <Typography variant="body2">
                                                          {patientDetailInfo.mobile
                                                              ? patientDetailInfo.mobile
                                : '-'}
                            </Typography>
                          </li>
                          <li>
                            <div className="font-14"> Enrolled On</div>
                                                      <Typography variant="body2">
                                                          {patientDetailInfo.enrolled_on
                                ? Moment(patientDetailInfo.enrolled_on).format(
                                  'MM-DD-YYYY',
                                )
                                : '-'}
                            </Typography>
                          </li>
                          <li>
                            <div className="font-14">
                              {' '}
                              Emergency Contact Name
                            </div>
                            <Typography variant="body2">
                              {' '}
                              {patientDetailInfo.emergency_contact_name
                                ? patientDetailInfo.emergency_contact_name
                                : '-'}
                            </Typography>
                          </li>
                          <li>
                            <div className="font-14">
                              {' '}
                              Emergency Contact Number
                            </div>
                            <Typography variant="body2">
                                                          {' '}
                              {patientDetailInfo.emergency_contact_number
                                ? patientDetailInfo.emergency_contact_number
                                : '-'}
                            </Typography>
                          </li>
                          <li>
                            <div className="font-14"> Status</div>
                            <Typography variant="body2">
                              {patientDetailInfo.status
                                ? patientDetailInfo.status
                                : '-'}
                            </Typography>
                          </li>
                          <li>
                            <div className="font-14"> Address</div>
                            <Typography variant="body2">
                             {patientDetailInfo.street_address_1
                                ? patientDetailInfo.street_address_1
                                : '-'}
                            {patientDetailInfo.street_address_2
                                ? ", " + patientDetailInfo.street_address_2
                                : ' '}
                            {patientDetailInfo.city
                                ? ", " + patientDetailInfo.city
                                : ' '}
                            {patientDetailInfo.state
                                ? ", " + patientDetailInfo.state
                                : ' '}
                            {patientDetailInfo.postal_code
                                ? " " + patientDetailInfo.postal_code
                                : ' '}
                            </Typography>
                          </li>
                        </ul>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <div className="report_table">
                      <TableContainer component={Paper}>
                        <div className="d-flex space-between table_header">
                          <div>
                            <Typography
                              variant="body2"
                              className="mr-15 SecondaryColor"
                            >
                              Reports
                            </Typography>
                          </div>
                          <div />
                        </div>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Report ID</TableCell>
                              <TableCell>Report Created</TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell>Upload to EDC</TableCell>
                              <TableCell>Date Uploaded</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {patientDetailInfo.report
                              && patientDetailInfo.report.map((row) => (
                                <TableRow key={row.report_id}>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    className="table_name"
                                  >
                                    {row.report_id}
                                  </TableCell>
                                  <TableCell className="table_data">
                                    {row.created_at
                                      ? Moment(row.created_at).utc().format(
                                        'MM-DD-YYYY HH:MM',
                                      )
                                      : '-'}
                                  </TableCell>

                                  <TableCell>
                                    <Button
                                      // onClick={handleClickOpen('paper')}
                                      onClick={(e) => {
                                        handleDownloadPreview(
                                          e,
                                          row.report_id,
                                          'paper',
                                        );
                                      }}
                                    >

                                      {row.report}
                                      Preview
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    <Button
                                      onClick={(e) => {
                                        handleDownloadPreview(
                                          e,
                                          row.report_id,
                                          'download',
                                        );
                                      }}
                                    >
                                    Download
                                    </Button>
                                      </TableCell>

                                      <TableCell>
                                    <Button
                                      disabled={
                                        (row.uploaded_ts
                                          && !(row.uploaded_ts === 'None')
                                          && !(row.uploaded_ts === ''))
                                      }
                                      onClick={(e) => {
                                        handleClickOpenConfirm(
                                          e,
                                          row.report_id,
                                        );
                                      }}
                                    >
                                      Confirm upload
                                    </Button>
                                 </TableCell>
                                <TableCell className="table_data">
                                    {row.uploaded_ts
                                        && !(row.uploaded_ts === 'None')
                                        && !(row.uploaded_ts === '')
                                        ? Moment(row.uploaded_ts).format(
                                            'MM-DD-YYYY',
                                        )
                                        : '-'}
                                </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </Grid>
                </Grid>
              </Container>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={confirmOpen}
        onClose={handleConfirmClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="confirm_dialog"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="body2">
            Are you sure that the report is uploaded?
          </Typography>
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={handleConfirmClose}
            color="primary"
            className="mr-15"
          >
            Go back
          </Button>
          <Button
            variant="contained"
            onClick={handleUploadConfirm}
            color="primary"
            autoFocus
          >
            I Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        className="preview_dialog"
      >
        <DialogTitle id="scroll-dialog-title">
          <Typography variant="body2">Preview</Typography>
        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText id="scroll-dialog-description">
            <div className="pdf__Page__canvas">
              <Document
                file={fileNameURL}
                onLoadSuccess={(pdf) => {
                  onDocumentLoadSuccess(pdf);
                }}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
              </Document>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

PatientDetails.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  counter: PropTypes.string,
};

export default withRouter(PatientDetails);
