// external imports
import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Link,
  Button,
  Typography,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { useHistory } from 'react-router-dom';
import ReactCodeInput from 'react-code-input';

// internal imports
import './Login.scss';
import JewelLogo from '../../assets/images/jewel logo.png'
import { AuthContext } from '../../context/AuthContextProvider';
import actions from '../../actions/Actions';
import AxiosInstance from '../../common/ApiHandler';
import apiEndPoints from '../../common/ApiEndPoints';
import {setAuthCookies, deleteCookie} from '../../common/ApiHelper';
// import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';

const Login = (props) => {
  const pinSize = 6;
  const history = useHistory();
  // force logout after 10 minutes
  const sessionTimeout = 600000;
  const PATIENT_LOGIN = 'patient';
  const PROVIDER_LOGIN = 'provider';
  // Subscribe to `authentication` state and access dispatch function
  // eslint-disable-next-line no-empty-pattern
  const [{ }, dispatch] = useContext(AuthContext); // , dispatch

  // Set props
  const { verifyInfo, verificationInfo } = props;
  const { patientLoginInfo, patientOtherInfo } = props;
  const { loginInfo, otherInfo } = props;

  // Declare a local state to be used internally by this component
  // Patient login info
  const [patientLoginDetails, setPatientLoginDetails] = useState(patientLoginInfo);
  const [patientOtherDetails, setPatientOtherDetials] = useState(patientOtherInfo);
  // Provider login info
  const [loginDetails, setLoginDetails] = useState(loginInfo);
  const [otherDetails, setOtherDetails] = useState(otherInfo);

  const wrapper = React.createRef();
  const [openL, setOpenL] = useState(false);
  const [dialogBoxText, setDialogBoxText] = useState("No Text");
  const [dialogTitle, setDialogTitle] = useState("No Title");
  const [snackbarText, setSnackbarText] = useState("Error");
  const [showMfa, setShowMfa] = useState(false);
  const [loginType, setLoginType] = useState(PATIENT_LOGIN);
  const [inputPinLen, setInputPinLen] = useState();
  const [verificationDetails, setVerificationDetails] = useState(verificationInfo);

  const pinCodeStyle = {
    className: ReactCodeInput,
    inputStyle: {
        margin:  '7px',
        MozAppearance: 'textfield',
        width: '60px',
        fontSize: '48px',
        textAlign: 'center',
        height: '60px',
    }
  }

  function Alert(alertProps) {
    const {
      onClose, severity, children, direction, style,
    } = alertProps;
    return (
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={onClose}
        severity={severity}
        style={style}
        direction={direction}
      >
        <div ref={wrapper}>{children}</div>
      </MuiAlert>
    );
  }

  const handleClose = (reason) => {
    setOtherDetails({
      ...otherDetails,
      [reason]: false,
    });
  };

  const handleCloseDialog = () => {
    setOpenL(false);
  };

  const handleClickForgotPassword = (dialogTitle, dialogText, user) => {
    // use the standard /ForgotPassword
    // this will avoid generic password for patients.

    history.push('/ForgotPassword');
    
  }

  // This is for redirect to list page after successful login
  useEffect(() => {
  }, [openL]);


  // This is for redirect to list page after successful login
  useEffect(() => {
    const timerLoginSuccess = setTimeout(() => {
      if (otherDetails.loginSuccess) {
        history.push('/PatientList');
      } else if (patientOtherDetails.loginSuccess) {
        // history.push(URL(process.env.REACT_APP_PATIENT_PORTAL));
      }
    }, 100);
    // this will clear Timeout when component unmount like in willComponentUnmount
    return () => {
      clearTimeout(timerLoginSuccess);
    };
  }, [otherDetails.loginSuccess]);

  // Patient form field change
  const onPatientTextFieldChange = (event) => {
    setPatientLoginDetails({
      ...patientLoginDetails,
      [event.target.id]: event.target.value,
    });
  }

  // Provider text field form change
  const onTextFieldChange = (event) => {
    setLoginDetails({
      ...loginDetails,
      [event.target.id]: event.target.value,
    });
  };

  const resetForm = () => {
    setLoginDetails({ provider_email: '', provider_password: '' });
  };

  const resetPatientForm = () => {
    setPatientLoginDetails({ email: '', password: ''});
  };

  const resetMFA = () => {
    setVerificationDetails({
      ...verificationDetails,
      mfa: ""
    });
    setInputPinLen(0);
  }

  // Provider login form
  const onLogin = () => {
    setLoginType(PROVIDER_LOGIN);
    setOtherDetails({
      ...otherDetails,
      submitted: true,
    });

    // Set loginDetails to correct format
    const requestDetails = {
      email: loginDetails.provider_email,
      mfa_code: "",
      password: loginDetails.provider_password,
    }

    dispatch({ type: actions.API_REQUEST_START });
    deleteCookie('accessToken');
    deleteCookie('refreshToken');
    AxiosInstance.post(apiEndPoints.LOGIN, requestDetails)
      .then((data) => {
        
        if(data.data.user_role == "Es_administrator"){
          setSnackbarText("Invalid Login");
          setOtherDetails({
            ...otherDetails,
            loginSuccess: false,
            loginError: true,
            submitted: false,
          });
          dispatch({
            type: actions.API_REQUEST_END,
          });
          return;
        }
        dispatch({
          type: actions.SET_USER_AUTHENTICATED,
          data: true,
        });

        if(data.data.isFirstTimeLogin === true & data.data.locked === false & data.data.user_role !== "Patient"){
          dispatch({
            type: actions.API_REQUEST_END,
          });
          history.push("/FirstLogin");
          return;
        }
        // Check if user is locked from account
        if (data.data.locked) {
          setOtherDetails({
            ...otherDetails,
            loginSuccess: false,
            loginError: true,
            submitted: false,
          });

          // Prompt the user to call Customer Service
          setOpenL(true);
          setDialogTitle("Account Locked");
          setDialogBoxText("To unlock account, please call customer support at 1-800-985-5702.");
          setSnackbarText("Account locked");
        } else {
          setSnackbarText("Invalid email or password");
        }

        // Set auth token and user role
        setAuthCookies(data.data.id_token, data.data.refresh_token);
        sessionStorage.setItem('userRole', data.data.user_role);
        sessionStorage.setItem('providerLogin', true);
        if (data.data.user_role === "Patient") {
          setOtherDetails({
            ...otherDetails,
            loginSuccess: false,
            loginError: true,
            submitted: false,
          });

          // Prompt the user to call Customer Service
          setSnackbarText("Invalid login");
          resetForm();
          resetMFA();
          dispatch({
            type: actions.API_REQUEST_END,
          });
          return;
        }

        

        sessionStorage.setItem(
          'userName',
          `${data.data.first_name} ${data.data.last_name}`,
        );

        sessionStorage.setItem('userStatus', `${data.data.user_status}`);
        sessionStorage.setItem('counter', sessionTimeout);
        resetForm();
        setOtherDetails({
          ...otherDetails,
          loginSuccess: true,
          loginError: false,
          submitted: false,
        });
        dispatch({
          type: actions.API_REQUEST_END,
        });
        dispatch({
          type: actions.LOGIN_SUCCESS,
          data: data.data,
        });
      })
      .catch(error => {
        try {
          if(error["message"] == "MFA Required"){
            setShowMfa(true);
            setLoginDetails({
              ...loginDetails,
              [requestDetails.id]: requestDetails.value,
            });
            dispatch({
              type: actions.API_REQUEST_END,
            });
            return;
          }
        } catch (err) {
        }
        resetForm();
        setSnackbarText("Login Failure");
        dispatch({
          type: actions.SET_USER_AUTHENTICATED,
          data: false,
        });
        
        setOtherDetails({
          ...otherDetails,
          loginSuccess: false,
          loginError: true,
          submitted: false,
        });
        dispatch({
          type: actions.API_REQUEST_END,
        });
        dispatch({
          type: actions.LOGIN_FAILURE,
          data: error.message,
        });
      });
  };

  const onPatientLogin = () => {
    setLoginType(PATIENT_LOGIN);
    setPatientOtherDetials({
      ...patientOtherDetails,
      submitted: true,
    });
    const requestDetails = {
      email: patientLoginDetails.email,
      mfa_code: "",
      password: patientLoginDetails.password,
    }
    dispatch({ type: actions.API_REQUEST_START });
    deleteCookie('accessToken');
    deleteCookie('refreshToken');
    AxiosInstance.post(apiEndPoints.PATIENT_LOGIN, requestDetails)
      .then((data) => {
        dispatch({
          type: actions.SET_USER_AUTHENTICATED,
          data: true,
        });
        
        // Check if user is locked from account
        if (data.locked) {
          setPatientOtherDetials({
            ...patientOtherDetails,
            loginSuccess: false,
            loginError: true,
            submitted: false,
          });

          // Prompt the user to call Customer Service
          setOpenL(true);
          setDialogTitle("Account Locked");
          setDialogBoxText("To unlock account, please call customer support at 1-800-985-5702.");
          setSnackbarText("Account locked");
        } else {
          setSnackbarText("Invalid email or password");
        }

        // Set auth token and user role
        sessionStorage.setItem('userRole', data.data.user_role);
        sessionStorage.setItem('patientLogin', true);
        setAuthCookies(data.data.id_token, data.data.refresh_token);
        
        resetPatientForm();
        setPatientOtherDetials({
          ...patientOtherDetails,
          loginSuccess: false,
          loginError: true,
          submitted: false,
        })
        dispatch({
          type: actions.API_REQUEST_END,
        });
        dispatch({
          type: actions.LOGIN_SUCCESS,
          data: data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actions.SET_USER_AUTHENTICATED,
          data: false,
        });
        try {
          if(error["message"] == "MFA Required"){
            setShowMfa(true);
            setLoginDetails({
              ...loginDetails,
              [requestDetails.id]: requestDetails.value,
            });
            dispatch({
              type: actions.API_REQUEST_END,
            });
            return;
          }
        } catch (err) {
        }
        resetPatientForm();
        
        setPatientOtherDetials({
          ...patientOtherDetails,
          loginSuccess: false,
          loginError: true,
          submitted: false,
        })
        dispatch({
          type: actions.API_REQUEST_END,
        });
        dispatch({
          type: actions.LOGIN_FAILURE,
          data: error.message,
        });
      });
  };

  const handleCloseMFA = () => {
    setShowMfa(false);
    setVerificationDetails({
      ...verificationDetails,
      mfa: ""
    });
    setInputPinLen(0);
  }

  const onPinCodeChange = (event) => {
    setVerificationDetails({
        ...verificationDetails,
        mfa: event
    });
    setInputPinLen(event.length);
};

const handleGenericLogin = (data) => {
  // Check if user is locked from account
  if (data.locked) {
    if(loginType == PROVIDER_LOGIN){
      setOtherDetails({
          ...otherDetails,
          loginSuccess: false,
          loginError: true,
          submitted: false,
       });
    }else{
      setPatientOtherDetials({
          ...patientOtherDetails,
        loginSuccess: false,
        loginError: true,
        submitted: false,
      });
    }
       
    // Prompt the user to call Customer Service
    setOpenL(true);
    setDialogTitle("Account Locked");
    setDialogBoxText("To unlock account, please call customer support at 1-800-985-5702.");
    setSnackbarText("Account locked");
  }else {
    setSnackbarText("Invalid email or password");
  }
  // Set auth token and user role
  sessionStorage.setItem('userRole', data.data.user_role);
  if(loginType == PATIENT_LOGIN){
    sessionStorage.setItem('patientLogin', true);
    sessionStorage.setItem('patientPortalToken', data.data.id_token);
    resetPatientForm();
    setPatientOtherDetials({
      ...patientOtherDetails,
      loginSuccess: false,
      loginError: true,
      submitted: false,
    })
  }

  if(loginType == PROVIDER_LOGIN){
    if (data.data.user_role === "Patient") {
      setOtherDetails({
        ...otherDetails,
        loginSuccess: false,
        loginError: true,
        submitted: false,
      });

      // Prompt the user to call Customer Service
      setSnackbarText("Invalid login");
      resetForm();
      dispatch({
          type: actions.API_REQUEST_END,
      });
      handleCloseMFA();
      return;
    }
    sessionStorage.setItem(
      'userName',
      `${data.data.first_name} ${data.data.last_name}`,
    );
    setAuthCookies(data.data.id_token, data.data.refresh_token);
    sessionStorage.setItem('userRole', data.data.user_role);
    sessionStorage.setItem('providerLogin', true);
    sessionStorage.setItem('userStatus', `${data.data.user_status}`);
    sessionStorage.setItem('counter', sessionTimeout);
    resetForm();
    setOtherDetails({
      ...otherDetails,
      loginSuccess: true,
      loginError: false,
      submitted: false,
    });
  }
  dispatch({
    type: actions.SET_USER_AUTHENTICATED,
    data: true,
  });
  dispatch({
  type: actions.API_REQUEST_END,
  });	
  dispatch({
    type: actions.LOGIN_SUCCESS,
    data: data.data,
  });
  handleCloseMFA();
} 

const handleGenericLoginError  = (error) => {
  dispatch({
    type: actions.SET_USER_AUTHENTICATED,
    data: false,
  });
  dispatch({
        type: actions.LOGIN_FAILURE,
        data: error.message,
  });
  if(loginType == PATIENT_LOGIN)resetPatientForm();
  if(loginType == PROVIDER_LOGIN)resetForm();
  handleCloseMFA();
  dispatch({
    type: actions.API_REQUEST_END,
  });
}

const handleSubmitMFA = () => {
  setOtherDetails({
    ...otherDetails,
    submitted: true,
  });

  // Set loginDetails to correct format
  const payload = {
    email: loginDetails.provider_email,
    password: loginDetails.provider_password,
    mfa_code: verificationDetails.mfa,
  }
  dispatch({ type: actions.API_REQUEST_START });
  if(loginType==PROVIDER_LOGIN){
    AxiosInstance.post(apiEndPoints.LOGIN, payload)
      .then(data => handleGenericLogin(data))
      .catch(error => handleGenericLoginError(error))
  }
  else if (loginType==PATIENT_LOGIN){
    const requestDetails = {
      email: patientLoginDetails.email,
      mfa_code: verificationDetails.mfa,
      password: patientLoginDetails.password,
    }
    AxiosInstance.post(apiEndPoints.PATIENT_LOGIN, requestDetails)
      .then((data) => handleGenericLogin(data))
      .catch((error) => handleGenericLoginError(error));
  }
}

  return (
    <div className="root">
      <div className="login_container">
        <div class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-fullWidth MuiInputBase-formControl"><img src={JewelLogo} alt="Logo" class="brand_logo"/></div>
        <div><p class="subtitle">CLINICAL STUDY PORTAL</p></div>
        <div className="login_windows">
          <div className="custom_container">
            <div id="patient" className="patient">
              <Snackbar
                data-testid="LoginSuccessSnackbar"
                open={otherDetails.loginSuccess}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={() => handleClose('loginSuccess')}
              >
                <Alert
                  onClose={() => handleClose('loginSuccess')}
                  severity="success"
                >
                  Log in successful!
                </Alert>
              </Snackbar>
              <Snackbar
                data-testid="LoginErrorSnackbar"
                open={otherDetails.loginError}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={() => handleClose('loginError')}
              >
                <Alert onClose={() => handleClose('loginError')} severity="error">
                  {snackbarText}
                </Alert>
              </Snackbar>
              <Dialog open={showMfa} fullWidth>
                <DialogContent>
                <DialogTitle sx={{fontSize:'30px'}}>Please Enter MFA Code</DialogTitle>
                <ReactCodeInput 
                  type='text' 
                  fields={pinSize} 
                  {...pinCodeStyle} 
                  className="confirmation-code" 
                  onChange={onPinCodeChange}
                  style={{
                      marginTop: "56px",
                  }}
                />
                <div style={{height:'25px'}} />
                <DialogActions>
                  <Button 
                  onClick={handleCloseMFA} 
                  variant="contained"
                  color="secondary"
                  >
                    Cancel
                  </Button>
                  <div style={{flex: '1 0 0'}} />
                  <Button 
                  onClick={handleSubmitMFA} 
                  variant="contained"
                  color="primary" 
                  disabled={inputPinLen !== pinSize ? true : false}
                  >
                    Submit
                  </Button>
                </DialogActions>
                </DialogContent>
              </Dialog>
              <ValidatorForm data-testid="PatientValidatorForm" onSubmit={onPatientLogin} className="validation-form">
                <div className="mb-5 patient-text text-h4">Patient</div>
                <div className="mb-5 text-h5">Please login to continue</div>
                <TextValidator
                  autoComplete='off'
                  fullWidth
                  label="Email"
                  margin="normal"
                  name="email"
                  type="email"
                  id="email"
                  data-testid="patient_email"
                  style={{
                    marginTop: "70px"
                  }}
                  value={patientLoginDetails.email}
                  InputProps={{ style: { fontSize: 30 }}}
                  InputLabelProps={{ shrink: true, style: { fontSize: 20, textAlign: "center"} }}
                  variant="outlined"
                  validators={['required', 'isEmail']}
                  errorMessages={['This field is required', 'Email is not valid']}
                  onChange={(e) => onPatientTextFieldChange(e)}
                />
                <TextValidator
                  autoComplete='off'
                  fullWidth
                  label="Password"
                  margin="normal"
                  name="password"
                  type="password"
                  id="password"
                  data-testid="patient_password"
                  style={{
                    marginTop: "34px"
                  }}
                  value={patientLoginDetails.password}
                  InputProps={{ style: { fontSize: 30 }, autoComplete: 'new-password' }}
                  InputLabelProps={{ shrink: true, style: { fontSize: 20, textAlign: "center"} }}
                  variant="outlined"
                  className="input_container"
                  validators={['required']}
                  errorMessages={['This field is required']}
                  onChange={(e) => onPatientTextFieldChange(e)}
                />
                <Button
                  id="patientLoginButton"
                  type="submit"
                  name="loginButton"
                  data-testid="loginPatientButton"
                  fullWidth
                  size="large"
                  style={{
                    backgroundColor: "#005f82",
                    color: "#fff",
                    height: "81px",
                    marginTop: "43px",
                    fontSize: "22px",
                    fontWeight: "600"
                  }}
                  variant="contained"
                  className="mb-20 login_btn_patient"
                  disabled={patientOtherInfo.submitted}
                >
                  Log in
                </Button>
                <Typography align="center" color="textSecondary" variant="body1">
                  <Button 
                    onClick={() => handleClickForgotPassword(
                      "Reset Password",
                      "Please call customer support at 1-800-985-5702.",
                      "patient")}
                    style={{
                      marginBottom: "74px",
                      fontSize: "20px",
                      fontWeight: "400"
                    }}
                  >
                    Forgot Password?
                  </Button>
                </Typography>
              </ValidatorForm>
            </div> {/* patient form */}
          </div> {/* custom container */}
          <div className="line-spacer"></div>
          <div className="custom_container">
            <div id="provider" className="provider">
              <Snackbar
                data-testid="LoginSuccessSnackbar"
                open={otherDetails.loginSuccess}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={() => handleClose('loginSuccess')}
              >
                <Alert
                  onClose={() => handleClose('loginSuccess')}
                  severity="success"
                >
                  Log in successful!
                </Alert>
              </Snackbar>
              <Snackbar
                data-testid="LoginErrorSnackbar"
                open={otherDetails.loginError}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={() => handleClose('loginError')}
              >
                <Alert onClose={() => handleClose('loginError')} severity="error">
                  {snackbarText}
                </Alert>
              </Snackbar>
              <ValidatorForm data-testid="ProviderValidatorForm" onSubmit={onLogin}>
                <div className="mb-5 text-h4">Provider</div>
                <div className="mb-5 text-h5">Please login to continue</div>
                <TextValidator
                  autoComplete='off'
                  fullWidth
                  label="Email"
                  margin="normal"
                  name="email"
                  type="email"
                  id="provider_email"
                  data-testid="provider_email"
                  style={{
                    marginTop: "70px"
                  }}
                  value={loginDetails.provider_email}
                  InputProps={{ style: { fontSize: 30}, autoComplete: 'off'}}
                  InputLabelProps={{ shrink: true, style: { fontSize: 20, textAlign: "center"} }}
                  variant="outlined"
                  validators={['required', 'isEmail']}
                  errorMessages={['This field is required', 'Email is not valid']}
                  onChange={(e) => onTextFieldChange(e)}
                  onFocus={()=> {}}
                />
                <TextValidator
                  autoComplete='off'
                  fullWidth
                  label="Password"
                  margin="normal"
                  name="password"
                  type="password"
                  id="provider_password"
                  data-testid="provider_password"
                  value={loginDetails.provider_password}
                  style={{
                    marginTop: "34px"
                  }}
                  InputProps={{ style: { fontSize: 30}, autoComplete: 'off'}}
                  InputLabelProps={{ shrink: true, style: { fontSize: 20, textAlign: "center"} }}
                  variant="outlined"
                  className="input_container"
                  validators={['required']}
                  errorMessages={['This field is required']}
                  onChange={(e) => onTextFieldChange(e)}
                />
                <Button
                  id="loginButton"
                  type="submit"
                  name="loginButton"
                  color="primary"
                  data-testid="loginButton"
                  fullWidth
                  size="large"
                  style={{
                    height: "81px",
                    marginTop: "43px",
                    fontSize: "22px",
                    fontWeight: "600"
                  }}
                  variant="contained"
                  className="mb-20 login_btn_provider"
                  disabled={otherInfo.submitted}
                >
                  Log in
                </Button>
                <Typography align="center" color="textSecondary" variant="body1">
                  <Button
                    name = 'forgetPasswordButton' 
                    onClick={() => handleClickForgotPassword(
                    "Reset Password",
                    "Please call customer support at 1-800-985-5702.",
                    "provider")}
                    style={{
                      marginBottom: "14px",
                      fontSize: "20px",
                      fontWeight: "400"
                    }}
                    >
                    Forgot Password?
                  </Button>
                  <h3>UDI: 00850047782083</h3>
                </Typography>
              </ValidatorForm>
            </div> {/* provider form */}
            <Dialog
              open={openL}
              onClose={handleCloseDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className="confirm_dialog"
            >
              <DialogTitle id="alert-dialog-title">
                <Typography variant="body2">
                  {dialogTitle}
                </Typography>
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="scroll-dialog-description">
                  {dialogBoxText}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  onClick={handleCloseDialog}
                  color="primary"
                  autoFocus
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </div> {/* custom container */}
        </div> {/* login windows */}
        <div
          style={{
            paddingTop: '200px',
            height: "34px",
            color: 'GrayText',
            alignContent: 'center'
          }}>
        </div>
      </div> {/* login container */}
    </div> //root
  );
};

Login.propTypes = {
  verificationInfo:PropTypes.shape({
    email: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    mfa: PropTypes.string.isRequired
  }),
  loginInfo: PropTypes.shape({
    provider_email: PropTypes.string.isRequired,
    provider_password: PropTypes.string.isRequired,
  }),
  otherInfo: PropTypes.shape({
    submitted: PropTypes.bool,
    loginSuccess: PropTypes.bool,
    loginError: PropTypes.bool,
  }),
  patientLoginInfo: PropTypes.shape({
    email: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
  }),
  patientOtherInfo: PropTypes.shape({
    submitted: PropTypes.bool,
    loginSuccess: PropTypes.bool,
    loginError: PropTypes.bool,
  })
};

Login.defaultProps = {
  verificationInfo:{
    email: "",
    password: "",
    mfa: ""
  },
  loginInfo: {
    provider_email: '',
    provider_password: '',
  },
  otherInfo: {
    submitted: false,
    loginSuccess: false,
    loginError: false,
  },
  patientLoginInfo: {
    email: '',
    password: '',
  },
  patientOtherInfo: {
    submitted: false,
    loginSuccess: false,
    loginError: false,
  }
};

export default Login;
