import React, {useState, useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import {
    IconButton,
    Button,
    Typography,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { useHistory } from 'react-router-dom';

// internal imports
import './Login.scss';
import JewelLogo from '../../assets/images/jewel logo.png';
import actions from '../../actions/Actions';
import { AuthContext } from '../../context/AuthContextProvider';
import AxiosInstance from '../../common/ApiHandler';
import apiEndPoints from '../../common/ApiEndPoints';

const PatientForgotPassword = (props) =>{
    const history = useHistory();
    const { otherInfo } = props;
    const [otherDetails, setOtherDetails] = useState(otherInfo);
    const [containerText, setContainerText] = useState("Forgot your password?");

    // This is for redirect to confirmation code after putting valid email address
    const onForgotPassword = () => {
        AxiosInstance.post(apiEndPoints.RESEND_PATIENT_PORTAL_PASSWORD, otherDetails)
        .then(() => {
            history.push("/PatientPasswordSent");
        })
        .catch(() => {
            history.push("/PasswordLimit");
        });
    };

    // Provider text field form change
    const onTextFieldChange = (event) => {
        setOtherDetails({
            ...otherDetails,
            [event.target.id]: event.target.value,
        });
    };

    return (
        <div className="root">
            <div className="login_container">
                <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-fullWidth MuiInputBase-formControl"><img src={JewelLogo} alt="Logo" className="brand_logo"/></div>
                <div><p className="subtitle">CLINICAL STUDY PORTAL</p></div>
                <div className="login_windows">
                    <div className="custom_container">
                        <div className="patient-forgot-password">
                        <ValidatorForm data-testid="ValidatorForm" onSubmit={onForgotPassword}>
                            <div className="patient-text text-h4">Patient</div>
                            <div style={{
                                marginTop: "50px",
                                textAlign: "start",
                                fontSize: "24px",
                                fontWeight: "600"
                            }}>{containerText}</div>
                            <div 
                            className="mt-5 pt-5 forgot-password-text" 
                            style={{
                                textAlign: "start",
                                fontSize: "24px"
                            }}>Enter the email address associated with your account.
                            </div>
                            <TextValidator
                            fullWidth
                            label="Email"
                            margin="normal"
                            name="user_email"
                            type="email"
                            placeholder="Email address"
                            id="user_email"
                            data-testid="user_email"
                            style={{
                                marginTop: "51px"
                            }}
                            value={otherDetails.user_email}
                            InputProps={{ style: { fontSize: 30 } }}
                            InputLabelProps={{ shrink: true, style: { fontSize: 20, textAlign: "center"} }}
                            variant="outlined"
                            validators={['required', 'isEmail']}
                            errorMessages={['Email is required', 'Email is not valid']}
                            onChange={(e) => onTextFieldChange(e)}
                            />
                            <Button
                            id="patientLoginButton"
                            type="submit"
                            name="loginButton"
                            data-testid="loginButton"
                            fullWidth
                            size="large"
                            style={{
                              backgroundColor: "#005f82",
                              color: "#fff",
                              height: "81px",
                              marginTop: "43px",
                              fontSize: "22px",
                              fontWeight: "600"
                            }}
                            variant="contained"
                            className="mb-20 login_btn_patient"
                            disabled={otherInfo.submitted}
                            >
                            Submit
                            </Button>
                        </ValidatorForm>
                        </div> {/* forgot password form */}
                    </div> {/* custom container */}
                </div> {/* login windows */}
            </div> {/* login container */}
        </div> //root
  );
}

PatientForgotPassword.propTypes = {
    otherInfo: PropTypes.shape({
        user_email: PropTypes.string,
    })
};

PatientForgotPassword.defaultProps = {
    otherInfo: {
        user_email: '',
    }
}

export default PatientForgotPassword;