// external imports
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Link,
  Button,
  Typography,
  Snackbar,
} from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { useHistory } from 'react-router-dom';

// internal imports
import './Login.scss';
import JewelLogo from '../../assets/images/jewel logo.png';
import actions from '../../actions/Actions';
import { AuthContext } from '../../context/AuthContextProvider';
import AxiosInstance from '../../common/ApiHandler';
import apiEndPoints from '../../common/ApiEndPoints';

const UpdatePassword = (props) => {
    const history = useHistory();
    const { passwordInfo } = props;
    const [passwordDetails, setPasswordDetails] = useState(passwordInfo);
    const [email, setEmail] = useState("");
    const [{ }, dispatch] = useContext(AuthContext); // , dispatch

    const styles = {
        errorStyle: {
            fontSize: '18px'
        }
    }

    useEffect(() => {
        setEmail(sessionStorage.getItem("userEmail"));
    }, []);

    // This is for redirect to confirmation code after putting valid email address
    const onUpdatePassword = () => {
        const requestPayload = {
            email: email,
            newpassword: passwordDetails.confirmedNewPassword
        }
        AxiosInstance.put(apiEndPoints.UPDATE_PASSWORD, requestPayload)
        .then((data) => {
            if(sessionStorage.getItem('firstLogin') === "true"){
                sessionStorage.setItem('userPassword', passwordDetails.confirmedNewPassword)
                sessionStorage.setItem('userEmail', email);
                history.push('/SelectMFA');
            }else{
                sessionStorage.clear();
                history.push("/PasswordChanged");
            }
        })
        .catch((error) => {
            dispatch({
                type: actions.API_RESPONSE_ERROR,
                data: error.message,
            });
        });
    };

    // New password field
    const onTextFieldChange = (event) => {
        setPasswordDetails({
            ...passwordDetails,
            [event.target.id]: event.target.value,
        });
    }

    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
        if (value !== passwordDetails.newPassword) {
            return false;
        }
        return true;
    });
    ValidatorForm.addValidationRule('passwordReq', (value) => {
        let rgex = new RegExp('(?=^.{8,}$)(?=.*[0-9])(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$');
        return rgex.test(value);
    });

    return (
        <div className="root">
            <div className="login_container">
                <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-fullWidth MuiInputBase-formControl"><img src={JewelLogo} alt="Logo" className="brand_logo"/></div>
                <div><p className="subtitle">CLINICAL STUDY PORTAL</p></div>
                <div className="login_windows">
                    <div className="custom_container">
                        <div className="forgot-password">
                        <ValidatorForm data-testid="ValidatorForm" onSubmit={onUpdatePassword}>
                            <div className="mb-4 mt-5 pt-5 forgot-password-title">Create New Password</div>
                            <div 
                                className="mt-5 pt-5 forgot-password-text"
                                style={{
                                    marginTop: "18px",
                                }}
                            >Your password must be 8 characters including one letter, one special character, and one number.</div>
                            <TextValidator
                                fullWidth
                                label="New Password"
                                margin="normal"
                                type="password"
                                placeholder="New Password"
                                id="newPassword"
                                data-testid="newPassword"
                                name="newPassword"
                                style={{
                                    marginTop: "50px"
                                }}
                                value={passwordDetails.newPassword}
                                InputProps={{ style: { fontSize: 30 } }}
                                InputLabelProps={{ shrink: true, style: { fontSize: 20, textAlign: "center"} }}
                                variant="outlined"
                                validators={['required', 'passwordReq']}
                                errorMessages={['New password is required', 'A password must be at least 8 characters long and include one letter, one special character and one number']}
                                errorStyle={{ shrink: true, style: { fontSize: 18}}}
                                onChange={onTextFieldChange}
                            />
                            <TextValidator
                                fullWidth
                                label="Confirm Password"
                                margin="normal"
                                type="password"
                                placeholder="Confirm Password"
                                id="confirmedNewPassword"
                                data-testid="confirmedNewPassword"
                                name="confirmedNewPassword"
                                style={{
                                    marginTop: "50px"
                                }}
                                value={passwordDetails.confirmedNewPassword}
                                InputProps={{ style: { fontSize: 30 } }}
                                InputLabelProps={{ shrink: true, style: { fontSize: 20, textAlign: "center"} }}
                                variant="outlined"
                                validators={['isPasswordMatch', 'required']}
                                errorMessages={['Passwords must match', 'Confirm password is required']}
                                errorStyle={{ shrink: true, style: { fontSize: 18}}}
                                onChange={onTextFieldChange}
                            />
                            <Button
                                id="updatePasswordButton"
                                type="submit"
                                name="updatePasswordButton"
                                color="primary"
                                data-testid="updatePasswordButton"
                                fullWidth
                                size="large"
                                style={{
                                    height: "81px",
                                    marginTop: "43px",
                                    fontSize: "22px",
                                    fontWeight: "600"
                                }}
                                variant="contained"
                                className="mb-20 login_btn_provider"
                                disabled={passwordInfo.submitted}
                            >
                            Continue
                            </Button>
                            <Typography align="center" color="textSecondary" variant="body1">
                            </Typography>
                        </ValidatorForm>
                        </div> {/* forgot password form */}
                    </div> {/* custom container */}
                </div> {/* login windows */}
            </div> {/* login container */}
        </div> //root
  );
};

UpdatePassword.propTypes = {
    passwordInfo: PropTypes.shape({
        newPassword: PropTypes.string,
        confirmedNewPassword: PropTypes.string,
    }),
};

UpdatePassword.defaultProps = {
    passwordInfo: {
        newPassword: '',
        confirmedNewPassword: '',
    },
};

export default UpdatePassword;
