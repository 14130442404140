// external imports
/*
* @jest-environment jest-environment-jsdom-sixteen
*/
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Link,
  Button,
  Typography,
  Snackbar,
  IconButton,
  Checkbox,
  FormGroup,
  MenuItem,
  FormControl,
  Select,
  InputLabel
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { useHistory } from 'react-router-dom';
import QRCode from 'react-qr-code';
// internal imports
import './Login.scss';
import JewelLogo from '../../assets/images/jewel logo.png';
import actions from '../../actions/Actions';
import { AuthContext } from '../../context/AuthContextProvider';
import AxiosInstance from '../../common/ApiHandler';
import apiEndPoints from '../../common/ApiEndPoints';
import ReactCodeInput from 'react-code-input';

const createTempRandomPassword = () => {
    let length              = 10;
    var result              = '';
    var characters          = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_=+';
    var charactersLength    = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * 
      charactersLength));
    }
   return result;
}

const SelectMfa = (props) => {
    const history = useHistory();
    const wrapper = React.createRef();
    const [{ }, dispatch] = useContext(AuthContext); // , dispatch
    const { otherInfo, verificationInfo } = props;
    const pinSize = 6;
    const [openL, setOpenL] = useState(false);
    const [dialogBoxText, setDialogBoxText] = useState("No Text");
    const [dialogTitle, setDialogTitle] = useState("No Title");
    const [inputPinLen, setInputPinLen] = useState();
    const [verificationDetails, setVerificationDetails] = useState(verificationInfo);
    const [containerText, setContainerText] = useState("Enter verification code");
    const [showQR, setShowQR] = useState(false);
    const [mfaType, setMfaType] = useState('email');
    const [mfaKey, setMfaKey] = useState('');
    const [email, setEmail] = useState("");
    const [containerTextStyle, setContainerTextStyle] = useState({
        color: "#707070",
        opacity: 1.0,
        fontSize: "35px",
        fontFamily: "Source Sans Pro",
        fontWeight: "400",
        lineHeight: 1.235,
        textAlign: "center",
    });

    const pinCodeStyle = {
        className: ReactCodeInput,
        inputStyle: {
            margin:  '7px',
            MozAppearance: 'textfield',
            width: '60px',
            fontSize: '48px',
            textAlign: 'center',
            height: '60px',
        }
    }

    // Revert text back to original text after API error response
    useEffect(() => {
        setTimeout(() => {
            setContainerText("Enter verification code");
            setContainerTextStyle({
                color: "#707070",
                opacity: 1.0,
                fontSize: "40px",
                fontFamily: "Source Sans Pro",
                fontWeight: "400",
                lineHeight: 1.235,
                textAlign: "center",
            });
        }, 7000);  // After 5.5 seconds revert text
    }, [containerText]);

    useEffect(() => {
        setVerificationDetails({
            ...verificationDetails,
            email: sessionStorage.getItem("userEmail"),
            password: createTempRandomPassword()
        });
    }, []);

    useEffect(() => {
        setEmail(sessionStorage.getItem("userEmail"));
    }, []);

    // This is for redirect to confirmation code after putting valid email address
    const onConfirmationCode = () => {
        const payload = {
            email: sessionStorage.getItem("userEmail"),
            mfa_code: verificationDetails.otp,
        }
        AxiosInstance.post(apiEndPoints.CHECK_MFA, payload)
        .then(() => {
            history.push("/Login");
        })
        .catch((error) => {
            dispatch({
                type: actions.API_RESPONSE_ERROR,
                data: error.message,
            });
            if(error["message"] == "Max OTP Attempts, Account Locked"){
                setContainerText("Max Attempts Reached, Account Locked");
            }else{
                setContainerText("Verification Code Invalid");
            }
            setContainerTextStyle({
                color: "#C90C0C",
                opacity: 1.0,
                fontSize: "40px",
                fontFamily: "Source Sans Pro",
                fontWeight: "400",
                lineHeight: 1.235,
                textAlign: "center",
            });
        });
    };

    // Resend validation code
    const handleResendValidationCode = () => {
        let payload = {email: sessionStorage.getItem("userEmail")};
        AxiosInstance.put(apiEndPoints.RESET_PASSWORD, payload)
        .then(() => {
            setContainerText("Code Resent");
            setContainerTextStyle({
                color: "#4A8928",
                opacity: 1.0,
                fontSize: "40px",
                fontFamily: "Source Sans Pro",
                fontWeight: "400",
                lineHeight: 1.235,
                textAlign: "center",
            });
        })
        .catch((error) => {
            setContainerText("Too many requests. Try again later.");
            setContainerTextStyle({
                color: "#C90C0C",
                opacity: 1.0,
                fontSize: "40px",
                fontFamily: "Source Sans Pro",
                fontWeight: "400",
                lineHeight: 1.235,
                textAlign: "center",
            });
        });
        setOpenL(false);
    }

    // Provider text field form change
    const onPinCodeChange = (event) => {
        setVerificationDetails({
            ...verificationDetails,
            otp: event
        });
        setInputPinLen(event.length);
    };

    // When Help button is selected
    const onHelp = () => {
        let dialogTitle = "Would you like a new verification code to be emailed to you?";
        let dialogText = "Resend verification code";
        setOpenL(true);
        setDialogTitle(dialogTitle)
        setDialogBoxText(dialogText)
    };

    const handleCloseDialog = () => {
        setOpenL(false);
    };


    const handleMfaChange = (event) => {
        //console.log(event.target.value)
        if(event.target.value == "google"){
            const auth_payload = {
                "email": sessionStorage.getItem('userEmail'),
                "password": sessionStorage.getItem('userPassword')
            }
            AxiosInstance.post(apiEndPoints.AUTH, auth_payload)
            .then(data => {
                sessionStorage.setItem('accessToken', data.data.id_token);
            })
            .catch(error => {
                console.log(error);
            });
            const payload = {
                'email': sessionStorage.getItem('userEmail'),
                'mfa_type': event.target.value
            }
            AxiosInstance.post(apiEndPoints.UPDATE_MFA, payload)
                .then(data => {
                    setMfaKey(data.data.mfa_key);
                })
                .catch(error => {
                    console.log(error);
                });
            setShowQR(true);
        }
        else{
            setShowQR(false);
        }
        setMfaType(event.target.value);
    }
    const handleMfaSave = (event) => {
        const auth_payload = {
            "email": sessionStorage.getItem('userEmail'),
            "password": sessionStorage.getItem('userPassword')
        }
        //Request Service Auth Key
        AxiosInstance.post(apiEndPoints.AUTH, auth_payload)
            .then(data => {
                sessionStorage.setItem('accessToken', data.data.id_token);
            })
            .catch(error => {
                console.log(error);
            });
        //Update MFA
        const payload = {
            'email': email,
            'mfa_type': mfaType
        }
        AxiosInstance.post(apiEndPoints.UPDATE_MFA, payload)
            .then(data => {
                setMfaKey(data.data.mfa_key);
            })
            .catch(error => {
                console.log(error);
            });
        
    }
    return (
        <div className="root">
            <div className="login_container">
                <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-fullWidth MuiInputBase-formControl"><img src={JewelLogo} alt="Logo" className="brand_logo"/></div>
                <div><p className="subtitle">CLINICAL STUDY PORTAL</p></div>
                <div className="login_windows">
                    <div className="mfa-select-container">
                        <div className="mfa-select">
                        <ValidatorForm data-testid="ValidatorForm" onSubmit={onConfirmationCode}>
                            <div style={{color: "#707070",
                                        opacity: 1.0,
                                        fontSize: "30px",
                                        fontFamily: "Source Sans Pro",
                                        fontWeight: "400",
                                        lineHeight: 1.235,
                                        marginBottom: "10px",
                                        textAlign: "center",}}
                            >
                            Please Select a Multi-Factor Authentication Method
                            </div>
                            <FormControl variant="filled" fullWidth sx={{ m: 1, minWidth: 120 }} onSubmit={handleMfaSave}>
                                <InputLabel style={{fontSize: "17px"}}>Multi-Factor Authentication Type</InputLabel>
                                <Select
                                id="mfa-selection"
                                label="MFA"
                                value={mfaType}
                                onChange={handleMfaChange}
                                >
                                    <MenuItem value={"email"} style={{fontSize: "15"}}>Email Code</MenuItem>
                                    <MenuItem value={"sms"}>Text Code</MenuItem>
                                    <MenuItem value={"google"}>Google Authenticator</MenuItem>
                                </Select>
                                <Button 
                                color="primary"
                                variant="contained"
                                className="mb-20 login_btn_provider"
                                style={{
                                    height: "55px",
                                    marginTop: "45px",
                                    fontSize: "20px",
                                    fontWeight: "600",
                                    display: showQR?'none':'block'
                                }}
                                onClick={(event) => handleMfaSave(event)}
                                >
                                Request Code
                                </Button>
                                <div style={{height:"65px", display: showQR?"none":'block'}}></div>
                            </FormControl>
                            <div style={{ height: "auto", margin: "0 auto", alignItems:"center", maxWidth: 192, width: "100%" }}>
                            <div style={{color: "#707070",
                                        opacity: 1.0,
                                        display: showQR?'block':'none',
                                        marginTop: "10px",
                                        fontSize: "15px",
                                        fontFamily: "Source Sans Pro",
                                        fontWeight: "400",
                                        lineHeight: 1.235,
                                        textAlign: "center",}}
                            >
                            Please scan QR code with Google Authenticator
                            </div>
                                <QRCode
                                size={256}
                                style={{ height: "auto", maxWidth: "100%", width: "100%", marginTop: '25px'}}
                                value={`otpauth://totp/Clinical%20Portal%20MFA?secret=${mfaKey}`}
                                viewBox={`0 0 256 256`}
                                display={showQR?'true':'none'}
                                />
                            </div>
                            <div className="mb-4 mt-5 pt-5" style={containerTextStyle}>{containerText}</div>
                            <ReactCodeInput 
                            type='text' 
                            fields={pinSize} 
                            {...pinCodeStyle} 
                            className="confirmation-code" 
                            onChange={onPinCodeChange}
                            style={{
                                marginTop: "56px",
                            }}
                            />
                            <Button
                            id="loginButton"
                            type="submit"
                            name="loginButton"
                            color="primary"
                            data-testid="loginButton"
                            fullWidth
                            size="large"
                            style={{
                                height: "81px",
                                marginTop: "43px",
                                fontSize: "22px",
                                fontWeight: "600"
                            }}
                            variant="contained"
                            className="mb-20 login_btn_provider"
                            disabled={inputPinLen !== pinSize ? true : false}
                            >
                            Continue
                            </Button>
                            <Typography align="right" color="textSecondary" variant="body1">
                            <Button
                                data-testid='helpButton' 
                                onClick={onHelp}
                                style={{
                                marginBottom: "74px",
                                fontSize: "20px",
                                fontWeight: "400"
                                }}
                                >
                                Help?
                            </Button>
                            </Typography>
                        </ValidatorForm>
                        </div> {/* forgot password form */}
                        <Dialog
                        open={openL}
                        onClose={handleCloseDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        className="confirm-dialog"
                        >
                        <div className="dialogCloseIcon">
                        <IconButton onClick={handleCloseDialog}>
                            <CloseIcon />
                        </IconButton>
                        </div>
                        <DialogTitle id="alert-dialog-title">
                            <Typography variant="h5" className="help-title">
                            <b>{dialogTitle}</b>
                            </Typography>
                        </DialogTitle>
                        <DialogContent className="mb-5 pb-5 help-title">
                            <DialogContentText id="scroll-dialog-description">
                            <Link
                                data-testid='resendCodeLink' 
                                onClick={handleResendValidationCode}
                                component="button"
                                variant="h5"
                                align="left"
                                >
                                {dialogBoxText}
                            </Link>
                            </DialogContentText>
                        </DialogContent>
                        </Dialog>
                    </div> {/* custom container */}
                </div> {/* login windows */}
            </div> {/* login container */}
        </div> //root
  );
};

SelectMfa.propTypes = {
    otherInfo: PropTypes.shape({
        confirmationCodeSent: PropTypes.bool,
        confirmationCodeFailedToSend: PropTypes.bool,
    }),
    verificationInfo: PropTypes.shape({
        email: PropTypes.string,
        otp: PropTypes.string,
        password: PropTypes.string,
    })
};

SelectMfa.defaultProps = {
    otherInfo: {
        confirmationCodeSent: false,
        confirmationCodeFailedToSend: false,
    },
    verificationInfo: {
        email: '',
        otp: '',
        password: '',
    },
};

export default SelectMfa;