// external imports
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  IconButton,
  Link,
  Button,
  Typography,
  Snackbar,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { useHistory } from 'react-router-dom';

// internal imports
import './Login.scss';
import JewelLogo from '../../assets/images/jewel logo.png';
import actions from '../../actions/Actions';
import { AuthContext } from '../../context/AuthContextProvider';
import AxiosInstance from '../../common/ApiHandler';
import apiEndPoints from '../../common/ApiEndPoints';

const ForgotPassword = (props) => {
    const history = useHistory();
    const wrapper = React.createRef();
    const { otherInfo, verificationInfo } = props;
    const [openL, setOpenL] = useState(false);
    const [dialogBoxText, setDialogBoxText] = useState("No Text");
    const [dialogTitle, setDialogTitle] = useState("No Title");
    const [otherDetails, setOtherDetails] = useState(otherInfo);
    const [verificationDetails, setVerificationDetails] = useState(verificationInfo);
    const [containerText, setContainerText] = useState("Forgot your password?");
    const [containerTextStyle, setContainerTextStyle] = useState({
        color: "#707070",
        opacity: 1.0,
        fontSize: "40px",
        fontFamily: "Source Sans Pro",
        fontWeight: "400",
        lineHeight: 1.235,
        textAlign: "center",
    });
    const [{ }, dispatch] = useContext(AuthContext); // , dispatch

    // Revert text back to original text after API error response
    useEffect(() => {
        setTimeout(() => {
            setContainerText("Forgot your password?");
            setContainerTextStyle({
                color: "#707070",
                opacity: 1.0,
                fontSize: "40px",
                fontFamily: "Source Sans Pro",
                fontWeight: "400",
                lineHeight: 1.235,
                textAlign: "center",
            });
        }, 7000);  // After 5.5 seconds revert text
    }, [containerText]);

    // This is for redirect to confirmation code after putting valid email address
    const onForgotPassword = () => {
        AxiosInstance.put(apiEndPoints.RESET_PASSWORD, verificationDetails)
        .then(() => {
            sessionStorage.setItem("userEmail", verificationDetails.email);
            history.push("/ConfirmationCode");
        })
        .catch((error) => {
            if(error["message"] == "Max OTP Attempts, Account Locked"){
                history.push("/PasswordLimit");
            }
            dispatch({
                type: actions.API_RESPONSE_ERROR,
                data: error.message,
            });
            setContainerText("Account reset instructions have been sent by email.");
            setContainerTextStyle({
                color: "#C90C0C",
                opacity: 1.0,
                fontSize: "40px",
                fontFamily: "Source Sans Pro",
                fontWeight: "400",
                lineHeight: 1.235,
                textAlign: "center",
            });
        });
    };

    // Provider text field form change
    const onTextFieldChange = (event) => {
        setVerificationDetails({
            ...verificationDetails,
            [event.target.id]: event.target.value,
        });
    };

    // When Help button is selected
    const handleHelpOpen = () => {
        let dialogTitle = "Did you forget the email you registered with?";
        let dialogText = "Please call Customer Service for support at 1-800-985-5702.";
        setOpenL(true);
        setDialogTitle(dialogTitle)
        setDialogBoxText(dialogText)
    };

    // Dismiss popup
    const handleCloseDialog = () => {
        setOpenL(false);
    };

    return (
        <div className="root">
            <div className="login_container">
                <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-fullWidth MuiInputBase-formControl"><img src={JewelLogo} alt="Logo" className="brand_logo"/></div>
                <div><p className="subtitle">CLINICAL STUDY PORTAL</p></div>
                <div className="login_windows">
                    <div className="custom_container">
                        <div className="forgot-password">
                        <ValidatorForm data-testid="ValidatorForm" onSubmit={onForgotPassword}>
                            <div className="mb-4 mt-5 pt-5" style={containerTextStyle}>{containerText}</div>
                            <div 
                            className="mt-5 pt-5 forgot-password-text" 
                            style={{
                                marginTop: "47px",
                                textAlign: "start",
                            }}>
                                Please enter your email address to receive a verification code.
                            </div>
                            <TextValidator
                            fullWidth
                            label="Email"
                            margin="normal"
                            name="email"
                            type="email"
                            placeholder="Email address"
                            id="email"
                            data-testid="email"
                            style={{
                                marginTop: "51px"
                            }}
                            value={verificationDetails.email}
                            InputProps={{ style: { fontSize: 30 } }}
                            InputLabelProps={{ shrink: true, style: { fontSize: 20, textAlign: "center"} }}
                            variant="outlined"
                            validators={['required', 'isEmail']}
                            errorMessages={['Email is required', 'Email is not valid']}
                            onChange={(e) => onTextFieldChange(e)}
                            />
                            <Button
                            id="loginButton"
                            type="submit"
                            name="loginButton"
                            color="primary"
                            data-testid="loginButton"
                            fullWidth
                            size="large"
                            style={{
                                height: "81px",
                                marginTop: "43px",
                                fontSize: "22px",
                                fontWeight: "600"
                            }}
                            variant="contained"
                            className="mb-20 login_btn_provider"
                            disabled={otherInfo.submitted}
                            >
                            Continue
                            </Button>
                            <Typography align="right" color="textSecondary" variant="body1" style={{justifyContent: "flex-end"}}>
                            <Button
                                data-testid='helpButton' 
                                onClick={handleHelpOpen}
                                style={{
                                marginBottom: "74px",
                                fontSize: "20px",
                                fontWeight: "400",
                                }}
                                >
                                Help?
                            </Button>
                            </Typography>
                        </ValidatorForm>
                        </div> {/* forgot password form */}
                        <Dialog
                        open={openL}
                        onClose={handleCloseDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        className="confirm-dialog"
                        >
                        <div className="dialogCloseIcon">
                        <IconButton onClick={handleCloseDialog} data-testid='closeHelpButton'>
                            <CloseIcon/>
                        </IconButton>
                        </div>
                        <DialogTitle id="alert-dialog-title">
                            <Typography variant="h5" className="help-title">
                            <b>{dialogTitle}</b>
                            </Typography>
                        </DialogTitle>
                        <DialogContent className="mb-5 pb-5 help-title">
                            <DialogContentText id="scroll-dialog-description">
                            <Typography variant="body1">
                            {dialogBoxText}
                            </Typography>
                            </DialogContentText>
                        </DialogContent>
                        </Dialog>
                    </div> {/* custom container */}
                </div> {/* login windows */}
            </div> {/* login container */}
        </div> //root
  );
};

ForgotPassword.propTypes = {
    otherInfo: PropTypes.shape({
        confirmationCodeSent: PropTypes.bool,
        confirmationCodeFailedToSend: PropTypes.bool,
    }),
    verificationInfo: PropTypes.shape({
        email: PropTypes.string,
    })
};

ForgotPassword.defaultProps = {
    otherInfo: {
        confirmationCodeSent: false,
        confirmationCodeFailedToSend: false,
    },
    verificationInfo: {
        email: '',
    },
};

export default ForgotPassword;
