import actions from '../actions/Actions';

// Providing initial status value
export const initialLoginState = {
  userDetails: {},
  isAuthenticated: false,
  loading: false,
  error: null,
};

export const authReducer = (state, action) => {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        userDetails: action.data,
      };
    case actions.LOGIN_FAILURE:
      return {
        ...state,
        error: action.data,
      };
    case actions.API_REQUEST_START:
      return {
        ...state,
        loading: true,
      };
    case actions.API_REQUEST_END:
      return {
        ...state,
        loading: false,
      };
    case actions.API_RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    case actions.SET_USER_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.data,
      };
    default:
      return state;
  }
};
