const ApiEndPoints = {
  AUTH: '/auth/token',
  LOGIN: '/client/auth',
  PATIENT_LOGIN: '/portal_login',
  GET_PATIENT_LIST: '/providers/patient_list',
  GET_PATIENT_DETAILS: '/patients/details',
  GET_REPORT_BY_ID: '/patients/report',
  REFRESH_TOKEN: 'refresh_user_token',
  TOKEN_VALID: '/validate_token',
  UPDATE_REPORT_UPLOAD_DATE: '/patients/report',
  RESET_PASSWORD: '/password/reset',
  RESEND_PATIENT_PORTAL_PASSWORD: '/password/resend_portal_password',
  UPDATE_PASSWORD: '/password/update',
  CONFIRM_EMAIL: '/confirm',
  UPDATE_MFA: '/mfa/update',
  CHECK_MFA: '/mfa/check'
};

export default ApiEndPoints;
