import React from 'react';
import { NavLink as RouterLink, withRouter } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Button, ListItem, makeStyles } from '@material-ui/core';
import { useIdleTimer } from 'react-idle-timer';
import { deleteCookie } from '../../common/ApiHelper';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%',
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  title: {
    marginRight: 'auto',
    fontSize: '14px',
    fontWeight: 600,
  },
  active: {
    color: theme.palette.primary.main,
    position: 'relative',

    '&::before': {
      content: ' ',
      position: 'absolute',
      width: '2px',
      height: '44px',
      backgroundColor: theme.palette.primary.main,
      left: 0,
    },
    '& $title': {
      fontWeight: 600,
    },
    '& $icon': {
      color: theme.palette.primary.main,
    },
  },
}));

const NavItem = ({
  className, href, icon: Icon, title,
}) => {
  const classes = useStyles();

  const handleOnIdle = () => {
    deleteCookie('accessToken');
    sessionStorage.removeItem('userName');
    sessionStorage.removeItem('userStatus');
    window.location.replace(`${window.location.origin}/Login`);
  };

  // eslint-disable-next-line no-unused-vars
  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: process.env.REACT_APP_SESSION_TIME_OUT,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  // use to refresh view if user is on same page
  const handleListClick = (e, currenthref) => {
    if (window.location.href.indexOf(currenthref) > -1) {
      switch(href){
        case("/PatientList"):
          window.location.reload();
          break;
        case("/Login"):
          handleOnIdle();
          break;
        default:
          break;
      }
    }
  };
  return (
    <ListItem
      onClick={(e) => {
        handleListClick(e, href);
      }}
      className={clsx(classes.item, className)}
      disableGutters
    >
      <Button
        activeClassName={classes.active}
        className={classes.button}
        component={RouterLink}
        to={href}
      >
        {Icon && <Icon className={classes.icon} size="20" />}
        <span className={classes.title}>{title}</span>
      </Button>
    </ListItem>
  );
};

NavItem.defaultProps = {
  className: '',
  href: '',
  icon: '',
  title: '',
};

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string,
};

export default withRouter(NavItem);
