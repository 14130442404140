const typography = {
  fontFamily: '"Source Sans Pro", sans-serif',
  h1: {
    fontWeight: 600,
    fontSize: '42px',
    lineHeight: '48px',
  },
  h2: {
    fontSize: '26px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '33px',
  },
  h3: {
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '28px',
  },
  body1: {
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '23px',
  },
  body2: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
  },
};

export default typography;
