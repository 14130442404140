import actions from '../actions/Actions';

// Providing initial status value
export const initialPatientState = {
  patientListInfo: {},
  patientDetails: {},
  reportUploadConfirmInfo: {},
  loading: false,
  error: null,
};

export const patientReducer = (state, action) => {
  switch (action.type) {
    case actions.PATIENT_LIST_SUCCESS:
      return {
        ...state,
        patientListInfo: action.data,
      };
    case actions.PATIENT_LIST_FAILURE:
      return {
        ...state,
        error: action.data,
      };
    case actions.PATIENT_DETAILS_SUCCESS:
      return {
        ...state,
        patientDetails: action.data,
      };
    case actions.PATIENT_DETAILS_FAILURE:
      return {
        ...state,
        error: action.data,
      };
    case actions.UPDATE_REPORT_UPLOAD_DATE_SUCCESS:
      return {
        ...state,
        reportUploadConfirmInfo: action.data,
      };
    case actions.UPDATE_REPORT_UPLOAD_DATE_FAILURE:
      return {
        ...state,
        error: action.data,
      };
    default:
      return state;
  }
};
