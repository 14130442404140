import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  Button,
  Menu,
  MenuItem,
  Typography
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from 'react-router-dom';
import { IC_MAIN_LOGO } from '../../constant/imageConst';
import { deleteCookie } from '../../common/ApiHelper';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    width: 60,
    height: 60,
  },
  navbar_logo: {
    maxHeight: 40,
    width: 'auto',
    height: '100%',
  },
  about_button: {
    marginRight:'20px',
    fontSize: '16px',
    color: 'white',
    fontFamily: 'Source Sans Pro,sans-serif'
  },
  about_menu: {
    width: '250px',
    paddingLeft:'15px'
  }
}));

const TopBar = ({ className, onMobileNavOpen }) => {
  const history = useHistory();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0}>
      <Toolbar data-testid="toolbar">
        <img
          src={IC_MAIN_LOGO}
          alt="Logo"
          className={classes.navbar_logo}
        />
        <Box flexGrow={1} />
        <Hidden mdDown>
          <div>
              <Typography
              className={classes.about_button}
              onClick={handleClick}>
              About
            </Typography>
            <Menu
              className={classes.about_menu}
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <Typography className={classes.about_menu} variant='h3'>Clinical Portal</Typography>
              <Typography className={classes.about_menu} variant='subtitle1'>UDI: 00850047782083</Typography>
              <Typography className={classes.about_menu} variant='subtitle1'>Version: 2.01.000</Typography>
            </Menu>
          </div>
          <Link to="/Login" onClick={() => {
            deleteCookie('accessToken');
            sessionStorage.removeItem('userName');
            sessionStorage.removeItem('userStatus');
            sessionStorage.removeItem('counter');
            window.location.replace(`${window.location.origin}/Login`);
          }}>
            Log Out
          </Link>
        </Hidden>
        <Hidden lgUp>
          <IconButton id="icMenuBtn" color="inherit" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};
TopBar.defaultProps = {
  className: '',
  onMobileNavOpen: () => {},
};

export default TopBar;
