import React, { useEffect, useContext, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

// Internal imports
import Login from './views/login/Login';
import ForgotPassword from './views/login/ForgotPassword';
import ConfirmationCode from './views/login/ConfirmationCode';
import ConfirmEmail from './views/login/ConfirmEmail';
import UpdatePassword from './views/login/UpdatePassword';
import PasswordChanged from './views/login/PasswordChanged';
import PatientForgotPassword from './views/login/PatientForgotPassword';
import PatientPasswordSent from './views/login/PatientPasswordSent';
import PasswordLimit from './views/login/PasswordLimit';
import PatientDetails from './views/Patient/PatientDetails/PatientDetails';
import PatientList from './views/Patient/PatientList/PatientList';
import FirstLogin from './views/login/FirstLogin';
import { PatientContextProvider } from './context/PatientContextProvider';
import { AuthContext } from './context/AuthContextProvider';
import CircularLoader from './components/loader/Loader';
import SelectMfa from './views/login/SelectMFA';
import { setAuthCookies, getAuthTokensFromCookies } from './common/ApiHelper';


const AppRoutes = () => {
  const [authState] = useContext(AuthContext); // , dispatch
  const [isLoading, setLoading] = useState(false);
  const [routes, setRoutes] = useState([])

  useEffect(() => {
    setLoading(authState.loading);
  }, [authState.loading]);

  const { accessToken, refreshToken } = getAuthTokensFromCookies();

  useEffect(() => {
    setRoutes(authOrUnauthRoutes);
  }, [accessToken]);

  useEffect(() => {
    setRoutes(authOrUnauthRoutes);
  }, [sessionStorage.getItem('patientPortalToken')]);

  const authOrUnauthRoutes = () => {
    const userRole = sessionStorage.getItem('userRole');
    
    const providerLogin = sessionStorage.getItem('providerLogin');
    const patientLogin = sessionStorage.getItem('patientLogin');

    const { accessToken, refreshToken } = getAuthTokensFromCookies();
    // For both patient and provder onSubmit forms
    // Both Patient and Provider have accessToken
    if(accessToken || sessionStorage.getItem('patientPortalToken')) {
      // Provider can have access to both portals
      if ((userRole === "Provider" || userRole === "Prescribing_provider" || userRole === "Outpatient_provider" || userRole === "Es_clinical_super_user") &&  providerLogin === "true") {
        return authorizedRoutes;
      } else if (patientLogin == "true") {
        return patientAuthorizedRoutes;
      } else {
        deinitLogins();
        return unauthorizedRoutes;
      }
    } else {
      deinitLogins();
      return unauthorizedRoutes;
    }
  }

  const deinitLogins = () => {
    sessionStorage.clear();
    localStorage.clear();
  }

  const unauthorizedRoutes = (
    <>
      <>
        <Route path="/" exact component={Login} />
      </>
      <>
        <Route path="/Login" exact component={Login} />
      </>
      <>
      <Route path="/ConfirmationCode" exact component={ConfirmationCode} /> 
      </>
      <>
        <Route path="/ForgotPassword" exact component={ForgotPassword} />
      </>
      <>
        <Route path="/PasswordChanged" exact component={PasswordChanged} />
      </>
      <>
        <Route path="/PatientForgotPassword" exact component={PatientForgotPassword} />
      </>
      <>
        <Route path="/PatientPasswordSent" exact component={PatientPasswordSent} />
      </>
      <>
        <Route path="/PasswordLimit" exact component={PasswordLimit} />
      </>
      <>
        <Route path="/SelectMFA" exact component={SelectMfa} />
      </>
      <>
        <Route path="/FirstLogin" exact component={FirstLogin} />
      </>
      <>
        <Redirect from="/*" to="/" />
      </>
    </>
  )

  const patientAuthorizedRoutes = (
    <>
      <>
        <Route path="/" exact component={() => {
          const token = sessionStorage.getItem("patientPortalToken");
          deinitLogins();
          window.location.href = process.env.REACT_APP_PATIENT_PORTAL + `?id=${token}`;
          return null;
        }} />
      </>
    </>
  )

  const authorizedRoutes = (
    <>
      <>
        <Route path="/"
          exact
          render={() => (
            <PatientContextProvider>
              <PatientList counter={sessionStorage.getItem('counter')} />
            </PatientContextProvider>
          )}
        />
      </>
      <>
        <Route
          path="/PatientDetails"
          exact
          render={() => (
            <PatientContextProvider>
              <PatientDetails counter={sessionStorage.getItem('counter')} />
            </PatientContextProvider>
          )}
        />
      </>
      <>
        <Route
          path="/PatientList"
          exact
          render={() => (
            <PatientContextProvider>
              <PatientList counter={sessionStorage.getItem('counter')} />
            </PatientContextProvider>
          )}
        />
      </>
      <>
        <Redirect from="/*" to="/" />
      </>
    </>
  )

  return (
    <>
      {isLoading ? <CircularLoader /> : null}
      <Router>
        <Switch>
         <Route path="/ConfirmEmail/"  component={ConfirmEmail} /> 
         <Route path="/UpdatePassword" exact component={UpdatePassword} />
         <Route path="/SelectMFA" exact component={SelectMfa}/>
          {routes}
          </Switch>
      </Router>
    </>
  );
};

export default AppRoutes;
